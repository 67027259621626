import React from 'react'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'
import { useSelector } from 'react-redux'
import Scrollbar from '../shared/ScrollBar'

const useStyles = makeStyles(() => ({
  mainContent: {
    width: 'calc(100% - 300px)',
    marginLeft: '300px',
    height: '100vh',
    paddingTop: 66,
    transition: 'all 0.2s ease-in-out',
  },
  content: {
    padding: [[20, 40]],
    position: 'relative',
  },
  drawerClose: {
    width: 'calc(100% - 105px)',
    marginLeft: 105,
  },
}))

export default (Minimal) => {
  const Hoc = ({ ...props }) => {
    const { className } = props
    const toggle = useSelector((state) => state.app.toggle_drawer)
    const classes = useStyles()

    return (
      <div
        className={clsx(
          classes.mainContent,
          className,
          !toggle && classes.drawerClose,
        )}
      >
        <Scrollbar>
          <div className={classes.content}>
            <Minimal {...props} />
          </div>
        </Scrollbar>
      </div>
    )
  }
  return Hoc
}
