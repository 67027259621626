export const AUTH = 'AUTH'
export const PATH = 'PATH'
export const DRAWER = 'DRAWER'
export const THUMB = 'THUMB'
export const TOAST = 'TOAST'
export const UPDATE = 'UPDATE'
export const COMP_DATA = 'COMP_DATA'
export const DELETE_ID = 'DELETE_ID'
export const NOTIFICATION_INFO = 'NOTIFICATION_INFO'
export const COST_REPORT_DATE = 'COST_REPORT_DATE'
export const PROJECT_ID = 'PROJECT_ID'
export const UPDATE_CONFORMATION = 'UPDATE_CONFORMATION'
export const LOGOUT = 'LOGOUT'
export const PROJECT_ADMIN_ROLE = 'PROJECT_ADMIN_ROLE'
export const PROJECT_ROLE_ID = 'PROJECT_ROLE_ID'
export const PROJECT_LIST = 'PROJECT_LIST'
export const APPROVER_ACTION = 'APPROVER_ACTION'
export const ADD_APPROVER = 'ADD_APPROVER'
export const REMOVE_APPROVER = 'REMOVE_APPROVER'
export const MAIN_APPROVER = 'MAIN_APPROVER'
export const SUBMISSION_TYPE_ACTION = 'SUBMISSION_TYPE_ACTION'
export const APPBAR_DRAWER = 'APPBAR_DRAWER'
export const APPROVED_NOTIFICATION_ID = 'APPROVED_NOTIFICATION_ID'
export const NOTIFICATION_STATUS = 'NOTIFICATION_STATUS'
export const SUBMISSION_TYPE = 'SUBMISSION_TYPE'
export const SUBMISSION_APPROVAL = 'SUBMISSION_APPROVAL'
export const PROJECT_DETAIL_ID = 'PROJECT_DETAIL_ID'
export const UPDATE_USER = 'UPDATE_USER'
export const UPDATE_COST_REPORT = 'UPDATE_COST_REPORT'
export const DELETE_USER = 'DELETE_USER'
export const DELETE_COST_REPORT = 'DELETE_COST_REPORT'
export const DELETE_SUBMISSION = 'DELETE_SUBMISSION'
export const COMPANY_ROLE = 'COMPANY_ROLE'
export const SUBMISSION_NOTIFICATION = 'SUBMISSION_NOTIFICATION'
export const SUBMISSION_NOTIFICATION_BADGE = 'SUBMISSION_NOTIFICATION_BADGE'
export const NOTIFICATION_TYPE = 'NOTIFICATION_TYPE'
export const SUBMISSION_THUMB_BADGE = 'SUBMISSION_THUMB_BADGE'
export const SUBMISSION_THUMB_ID = 'SUBMISSION_THUMB_ID'
export const PROJECT_ROLE_SELECTION = 'PROJECT_ROLE_SELECTION'
export const PROJECT_ADMIN_APPROVAL_MATRIX = 'PROJECT_ADMIN_APPROVAL_MATRIX'
export const NEXT_PDF = 'NEXT_PDF'
export const PREVIOUS_PDF = 'PREVIOUS_PDF'
export const PDF_INDEX = 'PDF_INDEX'
export const FINANCE_UPDATE = 'FINANCE_UPDATE'
export const REPORT_BUILDER_DATA = 'REPORT_BUILDER_DATA'
export const UPDATE_SIZE = 'UPDATE_SIZE'
export const MANAGER_THUMB_PERMISSION = 'MANAGER_THUMB_PERMISSION'
export const APPROVER_USER = 'APPROVER_USER'
export const PROJECT_NAME = 'PROJECT_NAME'
export const UPDATE_PRO_DETAILS = 'UPDATE_PRO_DETAILS'
export const DOWNLOAD_PDF = 'DOWNLOAD_PDF'
export const PROJECT_DETAIL_ACTIVE_STEP = 'PROJECT_DETAIL_ACTIVE_STEP'
export const CANCEL_APPROVER_RANGE = 'CANCEL_APPROVER_RANGE'
export const CA_SWITCH = 'CA_SWITCH'
export const CHAT_SUBMISSION_ID = 'CHAT_SUBMISSION_ID'
export const CHAT_SUBMISSION_DATA = 'CHAT_SUBMISSION_DATA'
export const SUBMISSION_CHAT = 'SUBMISSION_CHAT'
export const COMMENTS_NOTIFICATION_COUNT = 'COMMENTS_NOTIFICATION_COUNT'
export const APPROVAL_MATRIX_ROLE = 'APPROVAL_MATRIX_ROLE'
export const PROJECT_DETAIL = 'PROJECT_DETAIL'
export const SA_COMPANY_LIST = 'SA_COMPANY_LIST'
export const SA_PROJECT_LIST = 'SA_PROJECT_LIST'
export const SA_USER_LIST = 'SA_USER_LIST'
export const CA_TEAM_MEMBER_LIST = 'CA_TEAM_MEMBER_LIST'
export const CA_COST_REPORT_LIST = 'CA_COST_REPORT_LIST'
export const ALL_TEAM_MEMBER = 'ALL_TEAM_MEMBER'
export const ALL_COST_REPORT_LIST = 'ALL_COST_REPORT_LIST'
export const SIDEBAR_TOOLTIP = 'SIDEBAR_TOOLTIP'
export const AUTOFILL_SUBMISSION = 'AUTOFILL_SUBMISSION'
export const AUTOFILL_SUBMISSION_CHANGE = 'AUTOFILL_SUBMISSION_CHANGE'
export const AUTOFILL_SUBMISSION_INVOICE = 'AUTOFILL_SUBMISSION_INVOICE'
export const SUBMISSION_FORM_TYPE = 'SUBMISSION_FORM_TYPE'
export const UPLOADED_LEDGER_DATA = 'UPLOADED_LEDGER_DATA'
export const DELETE_ADDED_LEDGER_FILE = 'DELETE_ADDED_LEDGER_FILE'
export const CALL_LEDGER = 'CALL_LEDGER'
export const AUDIT_W9_DATA = 'AUDIT_W9_DATA'
export const AUDIT_POP_DATA = 'AUDIT_POP_DATA'
export const AUDIT_INVOICE_DATA = 'AUDIT_INVOICE_DATA'
export const UPDATE_AUDIT = 'UPDATE_AUDIT'
export const VIEW_AUDIT_DETAIL = 'VIEW_AUDIT_DETAIL'
export const REVIEWER_STATUS_FINANCE = 'REVIEWER_STATUS_FINANCE'
export const AUTOFILL_UPLOAD = 'AUTOFILL_UPLOAD'
export const SIDEBAR_TOUR = 'SIDEBAR_TOUR'
export const DASHBOARD_TOUR = 'DASHBOARD_TOUR'
export const COMPANY_CURRENT_PAGE = 'COMPANY_CURRENT_PAGE'
export const PROJECT_CURRENT_PAGE = 'PROJECT_CURRENT_PAGE'
export const USER_LIST_CURRENT_PAGE = 'USER_LIST_CURRENT_PAGE'
export const COMPANY_ADMIN_CURRENT_PAGE = 'COMPANY_ADMIN_CURRENT_PAGE'
export const PROJECT_ADMIN_CURRENT_PAGE = 'PROJECT_ADMIN_CURRENT_PAGE'
export const TOUR_STATUS = 'TOUR_STATUS'
export const CHAT_LOADER = 'CHAT_LOADER'
export const SHOW_CHAT_BUTTON = 'SHOW_CHAT_BUTTON'
export const EMAIL_CHAT_BUTTON = 'EMAIL_CHAT_BUTTON'
export const SUBMISSION_LIST = 'SUBMISSION_LIST'
export const MANAGER_APPROVER_PERMISSIONS = 'MANAGER_APPROVER_PERMISSION'
export const QUICK_BOOK_PAGE_NO = 'QUICK_BOOK_PAGE_NO'
export const QUICK_BOOK_SUBMISSION_TYPE = 'QUICK_BOOK_SUBMISSION_TYPE'
export const CHAT_GPT_SWITCH = 'CHAT_GPT_SWITCH'
export const PREVIOUS_VENDOR_DETAILS = 'PREVIOUS_VENDOR_DETAILS'
export const ADD_NOTES = 'ADD_NOTES'
export const EXPORT_COST_REPORT = 'EXPORT_COST_REPORT'
export const DELETE_FILTER_TEMPLATE_ID = 'DELETE_FILTER_TEMPLATE_ID'
export const APPLY_FILTER_TEMPLATE_ID = 'APPLY_FILTER_TEMPLATE_ID'
