import DashboardIcon from '@material-ui/icons/Dashboard'
import { ReactComponent as CostReports } from '../assets/images/Icons/CostReports.svg'
import { ReactComponent as GeneralLedger } from '../assets/images/Icons/General-ledger.svg'
import { ReactComponent as Project } from '../assets/images/Icons/Project.svg'
import { ReactComponent as Users } from '../assets/images/Icons/Users.svg'
import { ReactComponent as ApprovalMatrix } from '../assets/images/Icons/ApprovalMatrix.svg'
import { ReactComponent as Submissions } from '../assets/images/Icons/submissions.svg'
import { ReactComponent as ReportBuilder } from '../assets/images/Icons/report-builder.svg'
import { ReactComponent as Audit } from '../assets/images/Icons/audit-icon.svg'
import { ReactComponent as IntegrationIcon } from '../assets/images/Icons/IntegrationIcon.svg'
// import { ReactComponent as Wallet } from '../assets/images/Icons/wallet-icon.svg'

import {
  projectRoleAdmin,
  projectRoleManger,
  projectRoleAssistant,
  projectRoleBasic,
  projectRoleReviewer,
} from '../utils/constants'

const dashBoardIcon = <DashboardIcon />

const projectAdmin = (token) => {
  const { projectId: projectDetailI, projectName } = token

  return [
    {
      primary: `${projectName || 'Project Details'}`,
      icon: <Project />,
      path: `/project-details/${projectDetailI}`,
    },
    {
      primary: 'Team Members',
      icon: <Users />,
      path: `/project-details/${projectDetailI}/view-users`,
    },

    {
      primary: 'Approval Matrix',
      icon: <ApprovalMatrix />,
      path: `/project-details/${projectDetailI}/approval-matrix`,
    },

    // {
    //   primary: 'Cost Report Upload',
    //   icon: <CostReports />,
    //   path: `/project-details/${projectDetailI}/view-cost-report`,
    // },

    {
      primary: 'Submissions',
      icon: <Submissions />,
      path: `/project-details/${projectDetailI}/view-submission`,
    },
    {
      primary: 'Report Builder',
      icon: <ReportBuilder />,
      path: `/project-details/${projectDetailI}/report-builder`,
    },
    // {
    //   primary: 'Reports',
    //   icon: <CostReports />,
    //   path: `/project-details/${projectDetailI}/cost-report-budget`,
    // },
  ]
}

const projectManager = (token) => {
  const { projectId: projectDetailI, projectName } = token
  return [
    {
      primary: `${projectName || 'Project Details'}`,
      icon: <Project />,
      path: `/project-details/${projectDetailI}`,
    },
    {
      primary: 'Team Members',
      icon: <Users />,
      path: `/project-details/${projectDetailI}/view-users`,
    },
    {
      primary: 'Submissions',
      icon: <Submissions />,
      path: `/project-details/${projectDetailI}/view-submission`,
    },
    {
      primary: 'Report Builder',
      icon: <ReportBuilder />,
      path: `/project-details/${projectDetailI}/report-builder`,
    },
  ]
}

const projectAssistant = (token) => {
  const { projectId: projectDetailI, projectName } = token
  return [
    {
      primary: `${projectName || 'Project Details'}`,
      icon: <Project />,
      path: `/project-details/${projectDetailI}`,
    },
    {
      primary: 'Team Members',
      icon: <Users />,
      path: `/project-details/${projectDetailI}/view-users`,
    },
    {
      primary: 'Submissions',
      icon: <Submissions />,
      path: `/project-details/${projectDetailI}/view-submission`,
    },
  ]
}

const projectBasic = [
  {
    primary: 'Basic',
    icon: dashBoardIcon,
  },
  {
    primary: 'Purchase Basic',
    icon: dashBoardIcon,
  },
  {
    primary: 'Check Basic',
    icon: dashBoardIcon,
  },
  {
    primary: 'Integration',
    icon: dashBoardIcon,
  },
]

const projectReviewer = (token) => {
  const { projectId: projectDetailI, projectName } = token
  return [
    {
      primary: `${projectName || 'Project Details'}`,
      icon: <Project />,
      path: `/project-details/${projectDetailI}`,
    },
    {
      primary: 'Team Members',
      icon: <Users />,
      path: `/project-details/${projectDetailI}/view-users`,
    },
    {
      primary: 'Approval Matrix',
      icon: <ApprovalMatrix />,
      path: `/project-details/${projectDetailI}/approval-matrix`,
    },
    {
      primary: 'Submissions',
      icon: <Submissions />,
      path: `/project-details/${projectDetailI}/view-submission`,
    },
    {
      primary: 'Report Builder',
      icon: <ReportBuilder />,
      path: `/project-details/${projectDetailI}/report-builder`,
    },
    {
      primary: 'Cost Report',
      icon: <CostReports />,
      path: `/project-details/${projectDetailI}/view-cost-report`,
    },
    {
      primary: 'General Ledger Upload',
      icon: <GeneralLedger />,
      path: `/project-details/${projectDetailI}/general-ledger`,
    },
    {
      primary: 'Audit',
      icon: <Audit style={{ width: 26, marginLeft: '-3px', height: 26 }} />,
      path: `/project-details/${projectDetailI}/audit`,
    },
    {
      primary: 'Integrations',
      icon: (
        <IntegrationIcon
          style={{ width: 36, marginLeft: '-10px', height: 36 }}
        />
      ),
      path: `/integrations`,
    },

    // {
    //   primary: 'Reports',
    //   icon: <CostReports />,
    //   path: `/project-details/${projectDetailI}/cost-report-budget`,
    // },

    // {
    //   primary: 'Wallet',
    //   icon: <Wallet />,
    //   path: `/project-details/${projectDetailI}/wire-transfer`,
    // },
  ]
}

export default {
  [projectRoleAdmin]: projectAdmin,
  [projectRoleManger]: projectManager,
  [projectRoleAssistant]: projectAssistant,
  [projectRoleBasic]: [projectBasic],
  [projectRoleReviewer]: projectReviewer,
}
