import { lazy } from 'react'
import hoc from '../hoc'
import projectDetailRoutes from './projectDetailRoutes'
const ProjectAdminDashboard = lazy(() =>
  import('../presentation/Dashboard/ProjectAdmin'),
)

const ViewTeamMember = lazy(() =>
  import('../presentation/Dashboard/ProjectAdmin/ViewTeamMember'),
)
const ViewProjectViewDetail = lazy(() =>
  import('../presentation/Dashboard/ProjectAdmin/ProjectViewDetail'),
)
const ViewApprovalMatrix = lazy(() =>
  import('../presentation/Dashboard/ProjectAdmin/ViewApprovalMatrix'),
)
const ViewSubmission = lazy(() =>
  import('../presentation/Dashboard/ProjectAdmin/ViewSubmission'),
)
const ViewReportBuilder = lazy(() =>
  import('../presentation/Dashboard/ProjectAdmin/ReportBuilder'),
)
// const ViewProjectDetails = lazy(() =>
//   import('../presentation/Dashboard/ProjectAdmin/ProjectDetails'),
// )
const ViewCostReports = lazy(() =>
  import('../presentation/Dashboard/ProjectAdmin/ViewCostReport'),
)
const CostLedgerView = lazy(() =>
  import('../presentation/Dashboard/ProjectAdmin/CostLedgerView'),
)
const AuditView = lazy(() =>
  import('../presentation/Dashboard/ProjectAdmin/AuditView'),
)
const WireTransfer = lazy(() =>
  import('../presentation/Dashboard/ProjectAdmin/WireTransferView'),
)
const ViewProjectList = lazy(() =>
  import('../presentation/Dashboard/ProjectAdmin/ProjectList'),
)
const ViewIntegration = lazy(() =>
  import('../presentation/Dashboard/ProjectAdmin/Integration'),
)
const ViewCostReportsBudget = lazy(() =>
  import('../presentation/Dashboard/ProjectAdmin/CostReportBudget'),
)
// const ViewUserDetails = lazy(() =>
//   import('../presentation/Dashboard/ProjectAdmin/UserDetails'),
// )

// const AddCostReports = lazy(() =>
//   import('../presentation/Dashboard/ProjectAdmin/AddCostReport'),
// )

// const ViewCostReportDetails = lazy(() =>
//   import('../presentation/Dashboard/ProjectAdmin/CostReportDetails'),
// )

// const AddUser = lazy(() =>
//   import('../presentation/Dashboard/ProjectAdmin/AddUser'),
// )

// const AddCostReportFormat = lazy(() =>
//   import('../presentation/Dashboard/ProjectAdmin/AddCostFormat'),
// )

// const AddApprovalMatrix = lazy(() =>
//   import('../presentation/Dashboard/ProjectAdmin/AddApprovalsMatrix'),
// )
// const AddPurchaseOrder = lazy(() =>
//   import('../presentation/Dashboard/ProjectAdmin/PurchaseOrder'),
// )

// const ViewSubmissionDetail = lazy(() =>
//   import('../presentation/Dashboard/ProjectAdmin/ViewSubmissionDetails'),
// )

// const ChangePassword = lazy(() => import('../presentation/ChangePassword'))
const UserProfile = lazy(() => import('../presentation/UserProfile'))
const redirectUrl = '/login'

export default [
  {
    path: '/dashboard',
    component: hoc(ProjectAdminDashboard),
    auth: true,
    redirectUrl,
  },

  {
    path: '/project-details/:id/view-users',
    component: hoc(ViewTeamMember),
    auth: true,
    redirectUrl,
  },

  // {
  //   path: '/add-user',
  //   component: hoc(AddUser),
  //   auth: true,
  //   redirectUrl,
  // },

  // {
  //   path: '/add-cost-report',
  //   component: hoc(AddCostReports),
  //   auth: true,
  //   redirectUrl,
  // },

  {
    path: '/project-details/:id/approval-matrix',
    component: hoc(ViewApprovalMatrix),
    auth: true,
    redirectUrl,
  },

  {
    path: '/project-details/:id/view-submission',
    component: hoc(ViewSubmission),
    auth: true,
    redirectUrl,
  },
  {
    path: '/project-details/:id/report-builder',
    component: hoc(ViewReportBuilder),
    auth: true,
    redirectUrl,
  },

  {
    path: '/project-list',
    component: hoc(ViewProjectList),
    auth: true,
    redirectUrl,
  },
  // {
  //   path: '/user-details/:id',
  //   component: hoc(ViewUserDetails),
  //   auth: true,
  //   redirectUrl,
  // },

  // {
  //   path: '/project-detail/:id',
  //   component: hoc(ViewProjectDetails),
  //   auth: true,
  //   redirectUrl,
  // },
  {
    path: '/project-details/:id',
    component: hoc(ViewProjectViewDetail),
    auth: true,
    redirectUrl,
    exact: true,
  },
  // {
  //   path: '/submission-detail/:type/:id',
  //   component: hoc(ViewSubmissionDetail),
  //   auth: true,
  //   redirectUrl,
  // },
  {
    path: '/project-details/:id/view-cost-report',
    component: hoc(ViewCostReports),
    auth: true,
    redirectUrl,
  },
  {
    path: '/project-details/:id/cost-report-budget',
    component: hoc(ViewCostReportsBudget),
    auth: true,
    redirectUrl,
  },
  {
    path: '/project-details/:id/general-ledger',
    component: hoc(CostLedgerView),
    auth: true,
    redirectUrl,
  },
  {
    path: '/project-details/:id/audit',
    component: hoc(AuditView),
    auth: true,
    redirectUrl,
  },
  {
    path: '/integrations',
    component: hoc(ViewIntegration),
    auth: true,
    redirectUrl,
  },
  {
    path: '/project-details/:id/wire-transfer',
    component: hoc(WireTransfer),
    auth: true,
    redirectUrl,
  },

  // {
  //   path: '/cost-report-details/:id',
  //   component: hoc(ViewCostReportDetails),
  //   auth: true,
  //   redirectUrl,
  // },

  // {
  //   path: '/add-cost-report-format',
  //   component: hoc(AddCostReportFormat),
  //   auth: true,
  //   redirectUrl,
  // },

  // {
  //   path: '/add-approval-matrix',
  //   component: hoc(AddApprovalMatrix),
  //   auth: true,
  //   redirectUrl,
  // },
  // {
  //   path: '/change-password',
  //   component: hoc(ChangePassword),
  //   auth: true,
  //   redirectUrl,
  // },
  {
    path: '/user-profile',
    component: hoc(UserProfile),
    auth: true,
    redirectUrl,
  },
  // {
  //   path: '/add-submission',
  //   component: hoc(AddPurchaseOrder),
  //   auth: true,
  //   redirectUrl,
  // },
  ...projectDetailRoutes,
]
