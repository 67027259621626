// import ScreenLockRotationIcon from '@material-ui/icons/ScreenLockRotation'
import PersonAddIcon from '@material-ui/icons/PersonAdd'
import { ReactComponent as SuperAdminIcon } from '../assets/images/Icons/SuperAdmin.svg'
import { ReactComponent as Company } from '../assets/images/Icons/Company.svg'
import { ReactComponent as Project } from '../assets/images/Icons/Project.svg'
import { ReactComponent as Users } from '../assets/images/Icons/Users.svg'
// import { ReactComponent as Vendors } from '../assets/images/Icons/Vendors.svg'
// import { ReactComponent as CostReports } from '../assets/images/Icons/CostReports.svg'
import { ReactComponent as Integrations } from '../assets/images/Icons/Integrations.svg'
import {
  superAdminRole,
  companyAdminRole,
  projectAdminRole,
  commonRole,
  managerRole,
  assistantRole,
  reviewerRole,
} from '../utils/constants'

const superAdmin = [
  { primary: 'Company', icon: <Company />, path: '/view-companies' },
  {
    primary: 'Projects',
    icon: <Project />,
    path: '/view-projects',
  },
  { primary: 'Users', icon: <Users />, path: '/view-users' },
  // { primary: 'Vendors', icon: <Vendors />, path: '/view-vendors' },
]

const superAdminRoleList = [
  {
    path: '/dashboard',
    primary: 'Super Admin',
    icon: <SuperAdminIcon />,
  },
]

const companyAdmin = [
  { primary: 'Projects', icon: <Project />, path: '/view-projects' },
  {
    primary: 'Team Members',
    icon: <Users />,
    path: '/team-member',
  },
  // { primary: 'Vendors', icon: <Vendors />, path: '/view-vendors' },
  // {
  //   primary: 'Cost Report Upload',
  //   icon: <CostReports />,
  //   path: '/view-cost-report',
  // },
  { primary: 'Integrations', icon: <Integrations />, path: '/integrations' },
  // {
  //   primary: 'Change Password',
  //   icon: <ScreenLockRotationIcon />,
  //   path: '/change-password',
  // },
  {
    primary: 'Add Chart Account',
    icon: <PersonAddIcon />,
    path: '/add-chart-account',
  },
]

const companyAdminRoleList = [
  {
    path: '/dashboard',
    primary: 'Company Dashboard',
    icon: <SuperAdminIcon />,
  },
]

const projectAdmin = [
  {
    primary: 'Projects',
    icon: <Project />,
    path: '/project-list',
  },
  // {
  //   primary: 'Change Password',
  //   icon: <ScreenLockRotationIcon />,
  //   path: '/change-password',
  // },
]

const projectAdminRoleList = [
  {
    path: '/dashboard',
    primary: 'Project Dashboard',
    icon: <SuperAdminIcon />,
  },
]

export default {
  [superAdminRole]: [superAdminRoleList, superAdmin],
  [companyAdminRole]: [companyAdminRoleList, companyAdmin],
  [projectAdminRole]: [projectAdminRoleList, projectAdmin],
  [managerRole]: [projectAdminRoleList, projectAdmin],
  [assistantRole]: [projectAdminRoleList, projectAdmin],
  [reviewerRole]: [projectAdminRoleList, projectAdmin],
  [commonRole]: commonRole,
}
