import {
  DELETE_ADDED_LEDGER_FILE,
  CALL_LEDGER,
  UPDATE_AUDIT,
  VIEW_AUDIT_DETAIL,
  REVIEWER_STATUS_FINANCE,
  QUICK_BOOK_PAGE_NO,
  QUICK_BOOK_SUBMISSION_TYPE,
  PREVIOUS_VENDOR_DETAILS,
  ADD_NOTES,
  EXPORT_COST_REPORT,
  DELETE_FILTER_TEMPLATE_ID,
  APPLY_FILTER_TEMPLATE_ID,
} from '../constants'

export const deleteLedgerFileAction = (params) => (dispatch) => {
  dispatch({
    type: DELETE_ADDED_LEDGER_FILE,
    payload: params,
  })
}
export const callLedgerAction = (params) => (dispatch) => {
  dispatch({
    type: CALL_LEDGER,
    payload: params,
  })
}
export const auditUpdateAction = (params) => (dispatch) => {
  dispatch({
    type: UPDATE_AUDIT,
    payload: params,
  })
}
export const viewAuditDetailAction = (params) => (dispatch) => {
  dispatch({
    type: VIEW_AUDIT_DETAIL,
    payload: params,
  })
}
export const setReviewerStatusActions = (params) => (dispatch) => {
  dispatch({
    type: REVIEWER_STATUS_FINANCE,
    payload: params,
  })
}

export const setAuditTabTypeActions = (params) => (dispatch) => {
  dispatch({
    type: 'AUDIT_TAB_TYPE',
    payload: params,
  })
}

export const setQBPageNumberAction = (params) => (dispatch) => {
  dispatch({
    type: QUICK_BOOK_PAGE_NO,
    payload: params,
  })
}

export const setQBSubmissionActionType = (params) => (dispatch) => {
  dispatch({
    type: QUICK_BOOK_SUBMISSION_TYPE,
    payload: params,
  })
}

export const setPreviousVendorDetail = (params) => (dispatch) => {
  dispatch({
    type: PREVIOUS_VENDOR_DETAILS,
    payload: params,
  })
}

export const addNoteAction = (params) => (dispatch) => {
  dispatch({
    type: ADD_NOTES,
    payload: params,
  })
}
export const exportCostReportAction = (params) => (dispatch) => {
  dispatch({
    type: EXPORT_COST_REPORT,
    payload: params,
  })
}
export const deleteFilterTemplateAction = (params) => (dispatch) => {
  dispatch({
    type: DELETE_FILTER_TEMPLATE_ID,
    payload: params,
  })
}
export const applyFilterTemplateAction = (params) => (dispatch) => {
  dispatch({
    type: APPLY_FILTER_TEMPLATE_ID,
    payload: params,
  })
}
