import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { GET_NOTIFICATION } from '../graphQL/queries/query'
import {
  GET_COMPANY_NOTIFICATION_COUNT,
  GET_PROJECT_NOTIFICATION_COUNT,
  GET_SUBMISSION_NOTIFICATION,
} from '../graphQL/queries/submission'
import { thumbAction } from '../store/actions/app'
import { equal, notEqual, length } from '../utils/javascript'
import { reusableLazyQuery } from './commonFunctions'
import customDispatch from './customDispatch'
import {
  Approve,
  bellNotification,
  companyProjectRole,
  Reject,
  superAdminRole,
} from '../utils/constants'
import { keys } from '../utils/regex'
import { decodeProjectToken, decodeToken } from '../hooks/decodeToken'
import {
  getNewSubmissionList,
  getReviewerNotificationCount,
} from './getSubscriptionData'
import { setApproverAction } from '../store/actions/projectAdmin'
import {
  GET_REVIEWER_NOTIFICATION_COUNT,
  GET_REVIEWER_NOTIFICATION_LIST,
} from '../graphQL/queries/notification'

const getNotification = () => {
  const { cusDispatch } = customDispatch()
  const { role } = decodeToken()
  const submissionTotal = getNewSubmissionList()
  const { projectId, role: projectRole, userId } = decodeProjectToken()
  const notificationType = useSelector((state) => state.app.notification_info)
  const [notificationData, setNotificationData] = useState([])
  const [notificationCount, setNotificationCount] = useState({
    notificationCount: 0,
    thumbCount: 0,
  })
  const [seen, setSeen] = useState(false)
  const { data: getReviewerCount } = getReviewerNotificationCount()

  const thumbNotify = useSelector((state) => state.app.approveCompany_id)

  const notificationStatus = useSelector(
    (state) => state.app.notification_status,
  )
  const approvalData = useSelector(
    (state) => state.projectAdmin?.submission_notification,
  )
  const selectedProject = useSelector(
    (state) => state.projectAdmin?.project_detail_id,
  )

  const [getListOfNotification, { loading, data }] =
    reusableLazyQuery(GET_NOTIFICATION)
  const [getSubmissionNotification, { data: submissionNotification }] =
    reusableLazyQuery(GET_SUBMISSION_NOTIFICATION)
  const [getCountNotification, { data: count }] = reusableLazyQuery(
    GET_COMPANY_NOTIFICATION_COUNT,
  )

  const [getReviewerNotificationList, { data: reviewerNotificationList }] =
    reusableLazyQuery(GET_REVIEWER_NOTIFICATION_LIST)
  const [getProjectCountNotification, { data: projectCount }] =
    reusableLazyQuery(GET_PROJECT_NOTIFICATION_COUNT)
  const [getReviewerNotCount, { data: reviewerNotificationCount }] =
    reusableLazyQuery(GET_REVIEWER_NOTIFICATION_COUNT)

  useEffect(() => {
    if (equal(notificationType, bellNotification)) {
      if (equal(role, superAdminRole)) getListOfNotification()
      if (
        equal(projectRole, companyProjectRole) ||
        equal(projectRole, 'MANAGER')
      ) {
        getSubmissionNotification({
          variables: {
            ...{ projectId },
          },
        })
        setNotificationCount({
          ...notificationCount,
          notificationCount: 0,
        })
      }
      setSeen(true)
      if (equal(projectRole, 'REVIEWER')) {
        getReviewerNotificationList({
          variables: {
            projectId,
          },
        })
      }
    }
  }, [notificationType])

  useEffect(() => {
    if (equal(role, superAdminRole)) {
      getCountNotification()
    }

    if (
      equal(projectRole, companyProjectRole) ||
      equal(projectRole, 'MANAGER')
    ) {
      getProjectCountNotification()
    }

    if (equal(projectRole, 'REVIEWER')) {
      getReviewerNotCount({
        variables: {
          projectId,
        },
      })
    }
  }, [])

  useEffect(() => {
    if (projectId) {
      if (
        equal(projectRole, companyProjectRole) ||
        equal(projectRole, 'MANAGER')
      ) {
        getProjectCountNotification()
        setSeen(false)
      }
    }
  }, [projectId])

  useEffect(() => {
    if (selectedProject) {
      if (equal(projectRole, 'REVIEWER')) {
        getReviewerNotCount({
          variables: {
            projectId,
          },
        })
      }
    }
  }, [selectedProject])

  let submissionNotificationCount = 0
  if (equal(projectRole, companyProjectRole) || equal(projectRole, 'MANAGER')) {
    // submissionNotificationCount = getNewSubmissionList()
    submissionNotificationCount = submissionTotal
  }

  useEffect(() => {
    if (
      equal(projectRole, companyProjectRole) ||
      equal(projectRole, 'MANAGER')
    ) {
      setNotificationCount({
        notificationCount: seen
          ? submissionNotificationCount
          : projectCount?.getSubmissionNotificationCount?.notifications
              ?.unSeenSubmission + submissionNotificationCount || 0,
        thumbCount:
          projectCount?.getSubmissionNotificationCount?.notifications
            ?.unApprovedSubmission + submissionNotificationCount || 0,
      })
    }
  }, [submissionNotificationCount])

  useEffect(() => {
    if (equal(role, superAdminRole)) {
      setNotificationCount({
        notificationCount:
          count?.getCompanyNotificationCount?.unSeenCompanyCount || 0,
        thumbCount:
          count?.getCompanyNotificationCount?.unApprovedCompanyCount || 0,
      })
    }

    if (
      equal(projectRole, companyProjectRole) ||
      equal(projectRole, 'MANAGER')
    ) {
      setNotificationCount({
        notificationCount:
          projectCount?.getSubmissionNotificationCount?.notifications
            ?.unSeenSubmission || 0,
        thumbCount:
          projectCount?.getSubmissionNotificationCount?.notifications
            ?.unApprovedSubmission || 0,
      })
      cusDispatch(
        setApproverAction(
          projectCount?.getSubmissionNotificationCount?.notifications
            ?.isApprover,
        ),
      )
    }
  }, [count, projectCount])

  useEffect(() => {
    if (data?.getNotification?.companyList) {
      setNotificationData([...data?.getNotification?.companyList])
    }
  }, [data])

  useEffect(() => {
    const submissionResponse =
      submissionNotification?.getSubmissionNotification?.notificationList
    const arrayData = []
    if (submissionResponse && submissionResponse.length) {
      submissionResponse.forEach((item) => {
        const dataList = {}
        dataList.notificationType = item.notificationType
        dataList.vendorName = item?.notificationId?.vendorName
        dataList.id = item?.notificationId?._id
        dataList.status = item?.notificationId?.approvalStatus[0]?.status
        arrayData.push(dataList)
        setNotificationData(arrayData)
      })
    }
  }, [submissionNotification])

  useEffect(() => {
    if (equal(role, superAdminRole)) {
      if (thumbNotify && equal(notificationType, bellNotification)) {
        const index = notificationData.findIndex((indexValue) =>
          equal(indexValue.id, thumbNotify),
        )
        if (equal(notificationStatus, Reject)) {
          const updatedItems = notificationData.filter((elem) =>
            notEqual(elem.id, thumbNotify),
          )
          setNotificationData(updatedItems)
        } else {
          notificationData[index].status = Approve
          setNotificationData(notificationData)
          cusDispatch(thumbAction(''))
        }
      }
    }
  }, [thumbNotify])

  useEffect(() => {
    if (equal(notificationType, bellNotification)) {
      if (notEqual(length(keys(approvalData)), 0)) {
        const index = notificationData.findIndex((x) =>
          equal(x.id, approvalData.id),
        )
        notificationData[index].status = approvalData.approverType
        setNotificationData(notificationData)
      }
    }
  }, [approvalData])

  useEffect(() => {
    if (getReviewerCount) {
      const responseData =
        getReviewerCount?.newReviewerBallEvent?.unSeenReviewerCount

      if (responseData.length) {
        responseData.forEach((item) => {
          if (item?.userId === userId) {
            setNotificationCount({
              notificationCount: item?.count,
            })
          }
        })
      }
    }
  }, [getReviewerCount])

  useEffect(() => {
    if (reviewerNotificationList) {
      const responseData =
        reviewerNotificationList?.getReviewerNotificationList?.notificationList

      if (responseData.length) {
        setNotificationData(responseData)
      }
    }
  }, [reviewerNotificationList])

  useEffect(() => {
    if (reviewerNotificationCount) {
      const responseData =
        reviewerNotificationCount?.getReviewerNotificationCount
      setNotificationCount({
        notificationCount: responseData?.notificationCount,
      })
    }
  }, [reviewerNotificationCount])

  useEffect(() => {
    if (notificationType === 'bellNotification' && projectRole === 'REVIEWER') {
      setNotificationCount({
        notificationCount: 0,
      })
    }
  }, [notificationType])

  return [
    {
      notificationData,
      loading,
      notificationCount,
      setNotificationCount,
    },
  ]
}

export default getNotification
