import { combineReducers } from 'redux'
import app from './app'
import projectAdmin from './projectAdmin'
import dataList from './dataList'
import reviewer from './reviewer'

export default combineReducers({
  app,
  projectAdmin,
  dataList,
  reviewer,
})
