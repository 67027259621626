import '@fortawesome/fontawesome-free/css/all.min.css'
import React, { Suspense, useEffect } from 'react'
import { ThemeProvider } from '@material-ui/core/styles'
import { ApolloProvider } from '@apollo/client'
import { BrowserRouter as Router } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { useClearCache } from 'react-clear-cache'
import theme from './theme'
import Routing from './routing'
import './App.css'
import { apolloClient } from './graphQL/config/apollo'
import superAdminRoutes from './routing/superAdminRoutes'
import companyAdminRoutes from './routing/companyAdminRoutes'
import projectAdminRoutes from './routing/projectAdminRoutes'
import notFound from './routing/notFound'
import commonRoutes from './routing/commonRoutes'
import { default as list } from './routing/drawerList'
import authentication from './containers/authentication'
import Drawer from './shared/Drawer'
import AppBar from './shared/AppBar'
import { length, notEqual, reload } from './utils/javascript'
import {
  commonRole,
  companyAdminRole,
  superAdminRole,
  projectAdminRole,
  managerRole,
  assistantRole,
  reviewerRole,
} from './utils/constants'
import ToastMsg from './shared/ToastMsg'
import { decodeToken } from './hooks/decodeToken'
import customDispatch from './containers/customDispatch'
import { authAction, companyRoleAction } from './store/actions/app'
import { isLoggedIn } from './utils/localStorage'
import { keys } from './utils/regex'
import Loader from './shared/Loader'

const routing = {
  [superAdminRole]: [...commonRoutes, ...superAdminRoutes, ...notFound],
  [companyAdminRole]: [...commonRoutes, ...companyAdminRoutes, ...notFound],
  [projectAdminRole]: [...commonRoutes, ...projectAdminRoutes, ...notFound],
  [managerRole]: [...projectAdminRoutes, ...commonRoutes, ...notFound],
  [assistantRole]: [...projectAdminRoutes, ...commonRoutes, ...notFound],
  [reviewerRole]: [...projectAdminRoutes, ...commonRoutes, ...notFound],
  [commonRole]: [
    ...commonRoutes,
    ...superAdminRoutes,
    ...companyAdminRoutes,
    ...projectAdminRoutes,
    ...notFound,
  ],
}
const App = () => {
  const { authenticated } = authentication()
  const { cusDispatch } = customDispatch()
  const toastMessage = useSelector((state) => state.app.toast)
  const logoutMsg = useSelector((state) => state.app.logout)
  const { role } = decodeToken()

  useEffect(() => {
    if (logoutMsg) reload()
  }, [logoutMsg])
  useEffect(() => {
    cusDispatch(companyRoleAction(role))
    window.addEventListener('focus', () => {
      if (!isLoggedIn().loggedIn) cusDispatch(authAction(isLoggedIn().loggedIn))
    })
  }, [role])

  const { isLatestVersion, emptyCacheStorage } = useClearCache()

  useEffect(() => {
    if (!isLatestVersion && process.env.NODE_ENV === 'production') {
      emptyCacheStorage()
    }
  }, [isLatestVersion])

  return (
    <>
      <ApolloProvider client={apolloClient}>
        <ThemeProvider theme={theme}>
          <Router>
            {notEqual(list[authenticated.role], commonRole) && (
              <>
                <AppBar />
                <Drawer list={list[authenticated.role]} />
              </>
            )}
            <Suspense fallback={<Loader />}>
              <Routing
                {...{
                  authenticated,
                  routes: routing[authenticated.role],
                }}
              />
            </Suspense>
          </Router>
        </ThemeProvider>
      </ApolloProvider>
      {length(keys(toastMessage)) ? (
        <ToastMsg type={toastMessage.title} msg={toastMessage.description} />
      ) : (
        ''
      )}
    </>
  )
}

export default App
