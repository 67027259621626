// eslint-disable-next-line camelcase
import jwt_decode from 'jwt-decode'
import { decrypt, encrypt, lowerCase } from './javascript'

const pendoFunction = (data, proData) => {
  window.pendo.updateOptions({
    visitor: {
      id: data?._id,
      firstName: data?.firstName,
      lastName: data?.lastName,
      email: data?.email,
      role: data?.role,
    },
    account: {
      id: proData?.projectId,
      name: proData?.projectName,
      industry: proData?.role,
    },
  })

  if (window.gist && data) {
    window.gist.identify(`${data?._id}`, {
      id: data?._id,
      firstName: data?.firstName,
      lastName: data?.lastName,
      email: data?.email,
      userType: data?.role,
    })
  }
}

export const isLoggedIn = () => {
  const token = loadStateFn('token')
  const decodes = loadStateFn('projectToken')
  let decodedProjectToken
  if (decodes) {
    decodedProjectToken = jwt_decode(decodes)
  }
  const projectId = decodedProjectToken?.projectId || ''
  let decoded
  if (token) {
    decoded = jwt_decode(token)
  }

  if (decoded && Object.keys(decoded).length) {
    pendoFunction(decoded, decodedProjectToken)
  }

  return {
    loggedIn: !!token,
    role: lowerCase(decoded?.role || '') || 'common',
    token,
    projectToken: loadStateFn('projectToken'),
    projectRole: loadStateFn('projectRole')
      ? decrypt(loadStateFn('projectRole'))
      : '',
    projectId,
  }
}

export const loadStateFn = (params) => localStorage.getItem(params)

export const saveStateFn = (key, value) => localStorage.setItem(key, value)

export const setUserData = (data) => {
  saveStateFn('token', data.token)
}
export const forgetPasswordToken = (data) => {
  // console.log(`data`, data)
  saveStateFn('forgetPassToken', data)
}
export const setProjectWiseUserData = (data) => {
  saveStateFn('projectToken', data.projectToken)
  saveStateFn('projectRole', encrypt(lowerCase(data.projectRole)))
}

export const clearUserData = () => localStorage.clear()

export const removeStateFn = (key) => localStorage.removeItem(key)
