import { useState } from 'react'
import { useSelector } from 'react-redux'
import { clearUserData } from '../utils/localStorage'
import bellThumbNotificationInfo from './bellThumbNotificationInfo'
import changeRoute from './changeRoute'
import customDispatch from './customDispatch'
import { logoutAction } from '../store/actions/app'
const appBar = () => {
  const [anchorEl, setAnchorEl] = useState(null)
  const [anchorEl1, setAnchorEl1] = useState(null)
  const [anchorEl2, setAnchorEl2] = useState(null)

  const { cusDispatch } = customDispatch()
  const { route } = changeRoute()
  const toggle = useSelector((state) => state.app.toggle_drawer)

  const [{ checkBellNotification, checkThumbNotification }] =
    bellThumbNotificationInfo()

  const handleClick = (event, type) => {
    switch (type) {
      case 'one':
        setAnchorEl(event.currentTarget)
        break
      case 'two':
        setAnchorEl1(event.currentTarget)
        checkBellNotification()
        break
      default:
        setAnchorEl2(event.currentTarget)
        checkThumbNotification()
    }
  }

  const handleClose = (type) => {
    switch (type) {
      case 'one':
        setAnchorEl(null)
        route('/user-profile')
        break
      case 'two':
        setAnchorEl1(null)
        break
      default:
        setAnchorEl2(null)
    }
  }

  const logout = () => {
    clearUserData()
    cusDispatch(logoutAction(true))
    route('/')
  }

  return {
    toggle,
    handleClick,
    handleClose,
    setAnchorEl,
    anchorEl,
    anchorEl1,
    anchorEl2,
    logout,
  }
}
export default appBar
