import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { pathAction } from '../store/actions/app'
import customDispatch from './customDispatch'

const changeRoute = () => {
  const history = useHistory()
  const { cusDispatch } = customDispatch()
  const path = useSelector((state) => state.app.path)
  const route = (params) => {
    history.push(params)
    cusDispatch(pathAction(params))
  }
  return { path, route }
}

export default changeRoute
