import { purchaseOrder } from '../../utils/constants'
import {
  PROJECT_ROLE_ID,
  PROJECT_LIST,
  APPROVER_ACTION,
  ADD_APPROVER,
  REMOVE_APPROVER,
  MAIN_APPROVER,
  SUBMISSION_TYPE_ACTION,
  SUBMISSION_TYPE,
  SUBMISSION_APPROVAL,
  PROJECT_DETAIL_ID,
  UPDATE_USER,
  DELETE_USER,
  UPDATE_COST_REPORT,
  DELETE_COST_REPORT,
  DELETE_SUBMISSION,
  SUBMISSION_NOTIFICATION,
  SUBMISSION_NOTIFICATION_BADGE,
  NOTIFICATION_TYPE,
  SUBMISSION_THUMB_BADGE,
  SUBMISSION_THUMB_ID,
  PROJECT_ROLE_SELECTION,
  PROJECT_ADMIN_APPROVAL_MATRIX,
  NEXT_PDF,
  PREVIOUS_PDF,
  PDF_INDEX,
  FINANCE_UPDATE,
  REPORT_BUILDER_DATA,
  UPDATE_SIZE,
  MANAGER_THUMB_PERMISSION,
  APPROVER_USER,
  PROJECT_NAME,
  UPDATE_PRO_DETAILS,
  DOWNLOAD_PDF,
  PROJECT_DETAIL_ACTIVE_STEP,
  CANCEL_APPROVER_RANGE,
  CHAT_SUBMISSION_ID,
  CHAT_SUBMISSION_DATA,
  SUBMISSION_CHAT,
  COMMENTS_NOTIFICATION_COUNT,
  APPROVAL_MATRIX_ROLE,
  PROJECT_DETAIL,
  AUTOFILL_SUBMISSION_CHANGE,
  AUTOFILL_SUBMISSION_INVOICE,
  SUBMISSION_FORM_TYPE,
  AUTOFILL_UPLOAD,
  CHAT_LOADER,
  SHOW_CHAT_BUTTON,
  EMAIL_CHAT_BUTTON,
  SUBMISSION_LIST,
  MANAGER_APPROVER_PERMISSIONS,
  CHAT_GPT_SWITCH,
} from '../constants'

const initialState = {
  project_id: '',
  project_list: [],
  approver: false,
  add_approver: {},
  remove_approver: {},
  main_approver: false,
  submission_action: false,
  submission_type: purchaseOrder,
  submission_approval: {},
  project_detail_id: '',
  update_user_id: '',
  update_cost_Report: '',
  delete_user_id: '',
  delete_cost_report: '',
  delete_submission: '',
  submission_notification: {},
  notification_badge: '',
  notification_type: '',
  thumb_badge: '',
  thumb_id: '',
  project_role_selection: false,
  project_admin_matrix_id: '',
  next: false,
  pre: false,
  pdf_Index: '',
  finance_type: '',
  report_builder_dataList: {},
  size: '',
  manager_thumb_per: '',
  approver_user: '',
  project_name: '',
  update_project_details: false,
  download_pdf: false,
  project_detail_active_step: '',
  cancel_approval_range: false,
  chat_submission_id: '',
  chat_submission_data: {},
  chat_submission: {},
  notification_chat_id: '',
  approval_matrix_role: '',
  project_detail: false,
  submission_detail: false,
  autofill_submission_invoice: '',
  submission_form_type: '',
  autofill_upload: false,
  pageNumber: 1,
  chatPdfLoader: false,
  showChatEmailBtn: false,
  emailChatButtonStatus: {},
  submissionTableData: {
    // purchaseOrder: [],
    // wireTransfer: [],
  },
  managerPermissions: [],
  openAISwitch: false,
}

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case PROJECT_ROLE_ID:
      return {
        ...state,
        project_id: payload,
      }
    case PROJECT_LIST:
      return {
        ...state,
        project_list: payload,
      }
    case APPROVER_ACTION:
      return {
        ...state,
        approver: payload,
      }
    case ADD_APPROVER:
      return {
        ...state,
        add_approver: payload,
      }
    case REMOVE_APPROVER:
      return {
        ...state,
        remove_approver: payload,
      }
    case MAIN_APPROVER:
      return {
        ...state,
        main_approver: payload,
      }
    case SUBMISSION_TYPE_ACTION:
      return {
        ...state,
        submission_action: payload,
      }
    case SUBMISSION_TYPE:
      return {
        ...state,
        submission_type: payload,
      }
    case SUBMISSION_APPROVAL:
      return {
        ...state,
        submission_approval: payload,
      }
    case PROJECT_DETAIL_ID:
      return {
        ...state,
        project_detail_id: payload,
      }
    case UPDATE_USER:
      return {
        ...state,
        update_user_id: payload,
      }
    case DELETE_USER:
      return {
        ...state,
        delete_user_id: payload,
      }
    case UPDATE_COST_REPORT:
      return {
        ...state,
        update_cost_Report: payload,
      }
    case DELETE_COST_REPORT:
      return {
        ...state,
        delete_cost_report: payload,
      }
    case DELETE_SUBMISSION:
      return {
        ...state,
        delete_submission: payload,
      }
    case SUBMISSION_NOTIFICATION:
      return {
        ...state,
        submission_notification: payload,
      }
    case SUBMISSION_NOTIFICATION_BADGE:
      return {
        ...state,
        notification_badge: payload,
      }
    case NOTIFICATION_TYPE:
      return {
        ...state,
        notification_type: payload,
      }
    case SUBMISSION_THUMB_BADGE:
      return {
        ...state,
        thumb_badge: payload,
      }
    case SUBMISSION_THUMB_ID:
      return {
        ...state,
        thumb_id: payload,
      }
    case PROJECT_ROLE_SELECTION:
      return {
        ...state,
        project_role_selection: payload,
      }
    case PROJECT_ADMIN_APPROVAL_MATRIX:
      return {
        ...state,
        project_admin_matrix_id: payload,
      }
    case NEXT_PDF:
      return {
        ...state,
        next: payload,
      }
    case PREVIOUS_PDF:
      return {
        ...state,
        pre: payload,
      }
    case PDF_INDEX:
      return {
        ...state,
        pdf_Index: payload,
      }
    case FINANCE_UPDATE:
      return {
        ...state,
        finance_type: payload,
      }
    case REPORT_BUILDER_DATA:
      return {
        ...state,
        report_builder_dataList: payload,
      }
    case UPDATE_SIZE:
      return {
        ...state,
        size: payload,
      }
    case MANAGER_THUMB_PERMISSION:
      return {
        ...state,
        manager_thumb_per: payload,
      }
    case APPROVER_USER:
      return {
        ...state,
        approver_user: payload,
      }
    case PROJECT_NAME:
      return {
        ...state,
        project_name: payload,
      }
    case UPDATE_PRO_DETAILS:
      return {
        ...state,
        update_project_details: payload,
      }
    case DOWNLOAD_PDF:
      return {
        ...state,
        download_pdf: payload,
      }
    case PROJECT_DETAIL_ACTIVE_STEP:
      return {
        ...state,
        project_detail_active_step: payload,
      }
    case CANCEL_APPROVER_RANGE:
      return {
        ...state,
        cancel_approval_range: payload,
      }
    case CHAT_SUBMISSION_ID:
      return {
        ...state,
        chat_submission_id: payload,
      }
    case CHAT_SUBMISSION_DATA:
      return {
        ...state,
        chat_submission_data: payload,
      }
    case SUBMISSION_CHAT:
      return {
        ...state,
        chat_submission: payload,
      }
    case COMMENTS_NOTIFICATION_COUNT:
      return {
        ...state,
        notification_chat_id: payload,
      }
    case APPROVAL_MATRIX_ROLE:
      return {
        ...state,
        approval_matrix_role: payload,
      }
    case PROJECT_DETAIL:
      return {
        ...state,
        project_detail: payload,
      }
    case AUTOFILL_SUBMISSION_CHANGE:
      return {
        ...state,
        submission_detail: payload,
      }
    case AUTOFILL_SUBMISSION_INVOICE:
      return {
        ...state,
        autofill_submission_invoice: payload,
      }
    case SUBMISSION_FORM_TYPE:
      return {
        ...state,
        submission_form_type: payload,
      }
    case AUTOFILL_UPLOAD:
      return {
        ...state,
        autofill_upload: payload,
      }
    case 'PAGE_NO':
      return { ...state, pageNumber: payload }

    case CHAT_LOADER:
      return { ...state, chatPdfLoader: payload }

    case SHOW_CHAT_BUTTON:
      return { ...state, showChatEmailBtn: payload }

    case EMAIL_CHAT_BUTTON:
      return { ...state, emailChatButtonStatus: payload }

    case SUBMISSION_LIST:
      return { ...state, submissionTableData: { ...payload } }

    case MANAGER_APPROVER_PERMISSIONS:
      return { ...state, managerPermissions: payload }

    case CHAT_GPT_SWITCH:
      return { ...state, openAISwitch: payload }

    default:
      return state
  }
}
