import { toastAction } from '../store/actions/app'
import customDispatch from './customDispatch'
const dispatchToastMsg = () => {
  const { cusDispatch } = customDispatch()
  const toast = (type, msg) => {
    if (msg) {
      cusDispatch(
        toastAction({
          title: type,
          description: msg,
        }),
      )
    }
  }
  return { toast }
}

export default dispatchToastMsg
