import { gql } from '@apollo/react-hooks'

export const NEW_COMPANY_REQUEST_EVENT = gql`
  subscription Subscription {
    newCompanyRequestEvent {
      unSeenCompanyList {
        id
        isSeen
      }
      unApproveCompanyCount
    }
  }
`
export const GET_SUBMISSION_NOTIFICATION_LIST = gql`
  subscription newSubmissionRequestEvent($projectId: String!) {
    newSubmissionRequestEvent(projectId: $projectId) {
      unSeenSubmission
    }
  }
`

export const GET_SUBMISSION_THUMB_BADGE = gql`
  subscription ThumbNotification($projectId: String!) {
    newSubmissionRequestEvent(projectId: $projectId) {
      unApprovedSubmission
    }
  }
`
export const GET_SUBMISSION_NOTIFICATION_WITH_THUMB_LIST = gql`
  subscription ThumbNotification($projectId: String!) {
    newSubmissionRequestEvent(projectId: $projectId) {
      unApprovedSubmission
      unSeenSubmission
    }
  }
`

export const GET_SUBMISSION_APPROVAL_COUNT = gql`
  subscription approvalNotificationCount(
    $projectId: String!
    $userId: String!
  ) {
    newReviewerBallEvent(projectId: $projectId, userId: $userId) {
      unSeenReviewerCount
    }
  }
`

export const GET_MESSAGE_EVENT = gql`
  subscription GetMessageEvent(
    $projectId: ID!
    $submissionType: RequestType!
    $submissionId: ID!
  ) {
    getMessageEvent(
      messageEventInput: {
        projectId: $projectId
        submissionType: $submissionType
        submissionId: $submissionId
      }
    ) {
      message {
        eventName
        data {
          id
          createdAt
          text
          senderId {
            id
            role {
              role
            }
            firstName
            lastName
            picUpload
          }
          mentionId {
            id
            firstName
            lastName
          }
        }
      }
      error
    }
  }
`

export const CHAT_NOTIFICATION = gql`
  subscription GetMessageCountEvent($projectId: ID!) {
    getMessageCountEvent(projectId: $projectId) {
      message {
        data {
          submissionId
          text
          senderId {
            id
            firstName
            lastName
          }
          mentionId {
            id
            firstName
            lastName
          }
        }
        eventName
      }
      error
    }
  }
`
