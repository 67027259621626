import { lazy } from 'react'
import hoc from '../hoc'

const NewViewUserDetails = lazy(() =>
  import('../presentation/Dashboard/ProjectAdmin/UserDetails'),
)

// const NewAddCostReports = lazy(() =>
//   import('../presentation/Dashboard/ProjectAdmin/AddCostReport'),
// )

const NewAddUser = lazy(() =>
  import('../presentation/Dashboard/ProjectAdmin/AddUser'),
)

const AddPurchaseOrder = lazy(() =>
  import('../presentation/Dashboard/ProjectAdmin/PurchaseOrder'),
)

// const AddCostReportFormat = lazy(() =>
//   import('../presentation/Dashboard/ProjectAdmin/AddCostFormat'),
// )

const ViewSubmissionDetail = lazy(() =>
  import('../presentation/Dashboard/ProjectAdmin/ViewSubmissionDetails'),
)

// const ViewCostReportDetails = lazy(() =>
//   import('../presentation/Dashboard/ProjectAdmin/CostReportDetails'),
// )
const ViewCostReportLedgerFormat = lazy(() =>
  import('../presentation/Dashboard/ProjectAdmin/AddCostLedgerFormat'),
)
const ViewCostReportLedger = lazy(() =>
  import('../presentation/Dashboard/ProjectAdmin/AddCostLedger'),
)
const ViewAuditDetail = lazy(() =>
  import('../presentation/Dashboard/ProjectAdmin/ViewAuditDetail'),
)

const redirectUrl = '/login'
export default [
  {
    path: '/project-details/:id/add-user',
    component: hoc(NewAddUser),
    auth: true,
    redirectUrl,
  },

  // {
  //   path: '/project-details/:id/add-cost-report',
  //   component: hoc(NewAddCostReports),
  //   auth: true,
  //   redirectUrl,
  // },

  {
    path: '/project-details/:id/user-details/:id',
    component: hoc(NewViewUserDetails),
    auth: true,
    redirectUrl,
  },

  {
    path: '/project-details/:id/add-submission',
    component: hoc(AddPurchaseOrder),
    auth: true,
    redirectUrl,
  },
  // {
  //   path: '/project-details/:id/add-cost-report-format',
  //   component: hoc(AddCostReportFormat),
  //   auth: true,
  //   redirectUrl,
  // },
  {
    path: '/project-details/:id/submission-detail/:type/:id',
    component: hoc(ViewSubmissionDetail),
    auth: true,
    redirectUrl,
  },
  // {
  //   path: '/project-details/:id/cost-report-details/:id',
  //   component: hoc(ViewCostReportDetails),
  //   auth: true,
  //   redirectUrl,
  // },
  {
    path: '/project-details/:id/add-cost-ledger-format',
    component: hoc(ViewCostReportLedgerFormat),
    auth: true,
    redirectUrl,
  },
  {
    path: '/project-details/:id/add-cost-ledger',
    component: hoc(ViewCostReportLedger),
    auth: true,
    redirectUrl,
  },
  {
    path: '/project-details/:id/audit-detail/:type/:file/:id',
    component: hoc(ViewAuditDetail),
    auth: true,
    redirectUrl,
  },
]
