import {
  SA_COMPANY_LIST,
  SA_PROJECT_LIST,
  SA_USER_LIST,
  CA_TEAM_MEMBER_LIST,
  CA_COST_REPORT_LIST,
  ALL_TEAM_MEMBER,
  ALL_COST_REPORT_LIST,
  AUTOFILL_SUBMISSION,
  UPLOADED_LEDGER_DATA,
  AUDIT_W9_DATA,
  AUDIT_POP_DATA,
  AUDIT_INVOICE_DATA,
  COMPANY_CURRENT_PAGE,
  PROJECT_CURRENT_PAGE,
  USER_LIST_CURRENT_PAGE,
  COMPANY_ADMIN_CURRENT_PAGE,
  PROJECT_ADMIN_CURRENT_PAGE,
} from '../constants'

const initialState = {
  company_list: {
    list: [],
    pageInfo: {},
  },
  project_list: {
    list: [],
    pageInfo: {},
  },
  user_list: {
    list: [],
    pageInfo: {},
  },
  ca_team_member_list: {
    list: [],
    pageInfo: {},
  },
  ca_cost_report_list: {
    list: [],
    pageInfo: {},
  },
  all_team_member_list: {
    list: [],
    pageInfo: {},
  },
  all_cost_report_list: {
    list: [],
    pageInfo: {},
  },
  uploaded_ledger_list: {
    list: [],
    pageInfo: {},
  },
  audit_w9: {
    list: [],
    pageInfo: {},
  },
  audit_pop: {
    list: [],
    pageInfo: {},
  },
  audit_invoice: {
    list: [],
    pageInfo: {},
  },
  autofillData: {},
  companyCurrentPage: 1,
  projectCurrentPage: 1,
  usersListCurrentPage: 1,
  company_admin_currentPage: {
    teamMListCurrentPage: 1,
  },
  project_admin_currentPage: {
    teamMListCurrentPage: 1,
    costReportListCurrentPage: 1,
    auditViewCurrentPage: 1,
  },
}

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case SA_COMPANY_LIST:
      return {
        ...state,
        company_list: payload,
      }
    case SA_PROJECT_LIST:
      return {
        ...state,
        project_list: payload,
      }
    case SA_USER_LIST:
      return {
        ...state,
        user_list: payload,
      }
    case CA_TEAM_MEMBER_LIST:
      return {
        ...state,
        ca_team_member_list: payload,
      }
    case CA_COST_REPORT_LIST:
      return {
        ...state,
        ca_cost_report_list: payload,
      }
    case ALL_TEAM_MEMBER:
      return {
        ...state,
        all_team_member_list: payload,
      }
    case ALL_COST_REPORT_LIST:
      return {
        ...state,
        all_cost_report_list: payload,
      }
    case AUTOFILL_SUBMISSION:
      return {
        ...state,
        autofillData: payload,
      }
    case UPLOADED_LEDGER_DATA:
      return {
        ...state,
        uploaded_ledger_list: payload,
      }
    case AUDIT_W9_DATA:
      return {
        ...state,
        audit_w9: payload,
      }
    case AUDIT_POP_DATA:
      return {
        ...state,
        audit_pop: payload,
      }
    case AUDIT_INVOICE_DATA:
      return {
        ...state,
        audit_invoice: payload,
      }

    case COMPANY_CURRENT_PAGE:
      return {
        ...state,
        companyCurrentPage: payload,
      }

    case PROJECT_CURRENT_PAGE:
      return {
        ...state,
        projectCurrentPage: payload,
      }

    case USER_LIST_CURRENT_PAGE:
      return {
        ...state,
        usersListCurrentPage: payload,
      }

    case COMPANY_ADMIN_CURRENT_PAGE:
      return {
        ...state,
        company_admin_currentPage: payload,
      }

    case PROJECT_ADMIN_CURRENT_PAGE:
      return {
        ...state,
        project_admin_currentPage: payload,
      }

    default:
      return state
  }
}
