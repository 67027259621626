import { useSubscription } from '@apollo/client'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import {
  GET_SUBMISSION_NOTIFICATION_WITH_THUMB_LIST,
  NEW_COMPANY_REQUEST_EVENT,
  GET_SUBMISSION_APPROVAL_COUNT,
} from '../graphQL/subscription/subscription'
import { equal, length } from '../utils/javascript'
import { superAdminRole } from '../utils/constants'
import { isLoggedIn } from '../utils/localStorage'
import { decodeProjectToken } from '../hooks/decodeToken'

export const getNewCompanyList = () => {
  const { role } = isLoggedIn()
  const { data, loading } = useSubscription(NEW_COMPANY_REQUEST_EVENT)
  const [unSeen, setUnSeen] = useState([])
  const [comLen, setComLen] = useState([])
  useEffect(() => {
    const unSeenData = data?.newCompanyRequestEvent?.unSeenCompanyList.filter(
      (item) => !item.isSeen,
    )
    setUnSeen(unSeenData)
  }, [data])

  useEffect(() => {
    if (equal(role, superAdminRole)) {
      setComLen(length(unSeen))
    } else {
      setComLen([])
    }
  }, [unSeen])

  return [
    {
      data,
      loading,
      comLen,
      thumbCount: data?.newCompanyRequestEvent?.unApproveCompanyCount,
    },
  ]
}

export const getNewSubmissionList = () => {
  const { projectId } = decodeProjectToken()
  const notificationType = useSelector((state) => state.app.notification_info)
  const [submissionTotal, setSubmissionTotal] = useState(0)
  const [seen, setSeen] = useState(false)
  const { data: notificationData } = useSubscription(
    GET_SUBMISSION_NOTIFICATION_WITH_THUMB_LIST,
    {
      variables: {
        projectId: projectId || '',
      },
    },
  )

  useEffect(() => {
    if (notificationType) {
      setSeen(true)
    }
  }, [notificationType])

  useEffect(() => {
    const subscriptionNotification = notificationData?.newSubmissionRequestEvent
    if (
      subscriptionNotification?.unApprovedSubmission &&
      subscriptionNotification?.unSeenSubmission
    ) {
      setSubmissionTotal(seen ? 1 : submissionTotal + 1)
      setSeen(false)
    }
  }, [notificationData])

  return submissionTotal
}

export const getReviewerNotificationCount = () => {
  const { projectId, userId } = decodeProjectToken()
  const { data } = useSubscription(GET_SUBMISSION_APPROVAL_COUNT, {
    variables: {
      projectId,
      userId,
    },
  })
  return { data }
}
