import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import CircularProgress from '@material-ui/core/CircularProgress'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'fixed',
    backgroundColor: 'rgba(255,255,255,0.5)',
    left: 0,
    right: 0,
    bottom: 0,
    top: 0,
    height: '100%',
    zIndex: 9999,
    minHeight: 'calc(100vh - 64px)',
    '& .MuiCircularProgress-root': {
      width: '60px !important',
      height: '60px !important',
    },
    '& > * + *': {
      marginLeft: theme.spacing(2),
    },
  },
}))

const Loader = () => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <CircularProgress color="primary" />
    </div>
  )
}

export default Loader
