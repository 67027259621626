import { useSubscription } from '@apollo/client'
import { useEffect, useState } from 'react'
import { CHAT_NOTIFICATION } from '../../graphQL/subscription/subscription'
import { decodeProjectToken } from '../../hooks/decodeToken'
import { chatNotificationCountAction } from '../../store/actions/projectAdmin'
import customDispatch from '../customDispatch'

const getChatSubscriptionData = () => {
  const { cusDispatch } = customDispatch()
  const [chatNotification, setChatNotification] = useState({})
  const { projectId } = decodeProjectToken()

  const { data } = useSubscription(CHAT_NOTIFICATION, {
    variables: {
      projectId: projectId || '',
    },
  })

  useEffect(() => {
    if (data) {
      const response = data?.getMessageCountEvent?.message
      if (response?.eventName === 'MESSAGE_CREATED') {
        setChatNotification({
          name: `${response?.data?.senderId?.firstName} ${response?.data?.senderId?.lastName}`,
          msg: response?.data?.text,
        })
      }
      cusDispatch(chatNotificationCountAction(response?.data?.submissionId))
    }
  }, [data])

  return { chatNotification }
}

export default getChatSubscriptionData
