import {
  PROJECT_ROLE_ID,
  PROJECT_LIST,
  APPROVER_ACTION,
  ADD_APPROVER,
  REMOVE_APPROVER,
  MAIN_APPROVER,
  SUBMISSION_TYPE_ACTION,
  SUBMISSION_TYPE,
  SUBMISSION_APPROVAL,
  PROJECT_DETAIL_ID,
  UPDATE_USER,
  DELETE_USER,
  UPDATE_COST_REPORT,
  DELETE_COST_REPORT,
  DELETE_SUBMISSION,
  SUBMISSION_NOTIFICATION,
  SUBMISSION_NOTIFICATION_BADGE,
  NOTIFICATION_TYPE,
  SUBMISSION_THUMB_BADGE,
  SUBMISSION_THUMB_ID,
  PROJECT_ROLE_SELECTION,
  PROJECT_ADMIN_APPROVAL_MATRIX,
  NEXT_PDF,
  PREVIOUS_PDF,
  PDF_INDEX,
  FINANCE_UPDATE,
  REPORT_BUILDER_DATA,
  UPDATE_SIZE,
  MANAGER_THUMB_PERMISSION,
  APPROVER_USER,
  PROJECT_NAME,
  UPDATE_PRO_DETAILS,
  DOWNLOAD_PDF,
  PROJECT_DETAIL_ACTIVE_STEP,
  CANCEL_APPROVER_RANGE,
  CHAT_SUBMISSION_ID,
  CHAT_SUBMISSION_DATA,
  SUBMISSION_CHAT,
  COMMENTS_NOTIFICATION_COUNT,
  APPROVAL_MATRIX_ROLE,
  PROJECT_DETAIL,
  AUTOFILL_SUBMISSION_CHANGE,
  AUTOFILL_SUBMISSION_INVOICE,
  SUBMISSION_FORM_TYPE,
  AUTOFILL_UPLOAD,
  CHAT_LOADER,
  SHOW_CHAT_BUTTON,
  EMAIL_CHAT_BUTTON,
  SUBMISSION_LIST,
  MANAGER_APPROVER_PERMISSIONS,
  CHAT_GPT_SWITCH,
} from '../constants'

export const projectRoleIdAction = (params) => (dispatch) => {
  dispatch({
    type: PROJECT_ROLE_ID,
    payload: params,
  })
}
export const projectListAction = (params) => (dispatch) => {
  dispatch({
    type: PROJECT_LIST,
    payload: params,
  })
}
export const approverAction = (params) => (dispatch) => {
  dispatch({
    type: APPROVER_ACTION,
    payload: params,
  })
}
export const addApproverAction = (params) => (dispatch) => {
  dispatch({
    type: ADD_APPROVER,
    payload: params,
  })
}
export const removeApproverAction = (params) => (dispatch) => {
  dispatch({
    type: REMOVE_APPROVER,
    payload: params,
  })
}
export const mainApproverAction = (params) => (dispatch) => {
  dispatch({
    type: MAIN_APPROVER,
    payload: params,
  })
}
export const submissionTypeAction = (params) => (dispatch) => {
  dispatch({
    type: SUBMISSION_TYPE_ACTION,
    payload: params,
  })
}
export const submissionActionType = (params) => (dispatch) => {
  dispatch({
    type: SUBMISSION_TYPE,
    payload: params,
  })
}
export const submissionApprovalAction = (params) => (dispatch) => {
  dispatch({
    type: SUBMISSION_APPROVAL,
    payload: params,
  })
}
export const projectDetailIdAction = (params) => (dispatch) => {
  dispatch({
    type: PROJECT_DETAIL_ID,
    payload: params,
  })
}
export const updateUserAction = (params) => (dispatch) => {
  dispatch({
    type: UPDATE_USER,
    payload: params,
  })
}
export const deleteUserAction = (params) => (dispatch) => {
  dispatch({
    type: DELETE_USER,
    payload: params,
  })
}
export const updateCostReportAction = (params) => (dispatch) => {
  dispatch({
    type: UPDATE_COST_REPORT,
    payload: params,
  })
}
export const deleteCostReportAction = (params) => (dispatch) => {
  dispatch({
    type: DELETE_COST_REPORT,
    payload: params,
  })
}
export const deleteSubmissionAction = (params) => (dispatch) => {
  dispatch({
    type: DELETE_SUBMISSION,
    payload: params,
  })
}
export const submissionNotificationAction = (params) => (dispatch) => {
  dispatch({
    type: SUBMISSION_NOTIFICATION,
    payload: params,
  })
}
export const submissionNotificationBadgeAction = (params) => (dispatch) => {
  dispatch({
    type: SUBMISSION_NOTIFICATION_BADGE,
    payload: params,
  })
}
export const setNotificationTypeAction = (params) => (dispatch) => {
  dispatch({
    type: NOTIFICATION_TYPE,
    payload: params,
  })
}
export const submissionThumbBadgeAction = (params) => (dispatch) => {
  dispatch({
    type: SUBMISSION_THUMB_BADGE,
    payload: params,
  })
}
export const submissionThumbIdAction = (params) => (dispatch) => {
  dispatch({
    type: SUBMISSION_THUMB_ID,
    payload: params,
  })
}
export const projectRoleSelectionAction = (params) => (dispatch) => {
  dispatch({
    type: PROJECT_ROLE_SELECTION,
    payload: params,
  })
}
export const projectAdminApprovalMatrixAction = (params) => (dispatch) => {
  dispatch({
    type: PROJECT_ADMIN_APPROVAL_MATRIX,
    payload: params,
  })
}
export const nextPdfAction = (params) => (dispatch) => {
  dispatch({
    type: NEXT_PDF,
    payload: params,
  })
}
export const previousPdfAction = (params) => (dispatch) => {
  dispatch({
    type: PREVIOUS_PDF,
    payload: params,
  })
}
export const pdfIndexAction = (params) => (dispatch) => {
  dispatch({
    type: PDF_INDEX,
    payload: params,
  })
}
export const updateFinanceAction = (params) => (dispatch) => {
  dispatch({
    type: FINANCE_UPDATE,
    payload: params,
  })
}
export const getReportBuilderDataAction = (params) => (dispatch) => {
  dispatch({
    type: REPORT_BUILDER_DATA,
    payload: params,
  })
}
export const setDialogSizeAction = (params) => (dispatch) => {
  dispatch({
    type: UPDATE_SIZE,
    payload: params,
  })
}
export const setManagerThumbPerAction = (params) => (dispatch) => {
  dispatch({
    type: MANAGER_THUMB_PERMISSION,
    payload: params,
  })
}
export const setApproverAction = (params) => (dispatch) => {
  dispatch({
    type: APPROVER_USER,
    payload: params,
  })
}
export const setProjectNameAction = (params) => (dispatch) => {
  dispatch({
    type: PROJECT_NAME,
    payload: params,
  })
}
export const updateProjectDetailsAction = (params) => (dispatch) => {
  dispatch({
    type: UPDATE_PRO_DETAILS,
    payload: params,
  })
}
export const downloadPdfAction = (params) => (dispatch) => {
  dispatch({
    type: DOWNLOAD_PDF,
    payload: params,
  })
}
export const projectDetailActiveStepAction = (params) => (dispatch) => {
  dispatch({
    type: PROJECT_DETAIL_ACTIVE_STEP,
    payload: params,
  })
}
export const cancelApprovalRangeAction = (params) => (dispatch) => {
  dispatch({
    type: CANCEL_APPROVER_RANGE,
    payload: params,
  })
}
export const chatSubIdAction = (params) => (dispatch) => {
  dispatch({
    type: CHAT_SUBMISSION_ID,
    payload: params,
  })
}
export const chatSubDataAction = (params) => (dispatch) => {
  dispatch({
    type: CHAT_SUBMISSION_DATA,
    payload: params,
  })
}
export const subChatAction = (params) => (dispatch) => {
  dispatch({
    type: SUBMISSION_CHAT,
    payload: params,
  })
}
export const chatNotificationCountAction = (params) => (dispatch) => {
  dispatch({
    type: COMMENTS_NOTIFICATION_COUNT,
    payload: params,
  })
}
export const approvalMatrixRoleAction = (params) => (dispatch) => {
  dispatch({
    type: APPROVAL_MATRIX_ROLE,
    payload: params,
  })
}
export const projectDetailAction = (params) => (dispatch) => {
  dispatch({
    type: PROJECT_DETAIL,
    payload: params,
  })
}
export const autoFillSubmissionChangeAction = (params) => (dispatch) => {
  dispatch({
    type: AUTOFILL_SUBMISSION_CHANGE,
    payload: params,
  })
}
export const autoFillSubmissionInvoiceAction = (params) => (dispatch) => {
  dispatch({
    type: AUTOFILL_SUBMISSION_INVOICE,
    payload: params,
  })
}
export const submissionFormAction = (params) => (dispatch) => {
  dispatch({
    type: SUBMISSION_FORM_TYPE,
    payload: params,
  })
}
export const autofillUpdateAction = (params) => (dispatch) => {
  dispatch({
    type: AUTOFILL_UPLOAD,
    payload: params,
  })
}

export const setPageNumberAction = (params) => (dispatch) => {
  dispatch({
    type: 'PAGE_NO',
    payload: params,
  })
}

export const setChatPDFLoaderAction = (params) => (dispatch) => {
  dispatch({
    type: CHAT_LOADER,
    payload: params,
  })
}

export const emailNotificationBtnStatus = (params) => (dispatch) => {
  dispatch({
    type: SHOW_CHAT_BUTTON,
    payload: params,
  })
}

export const emailChatNotificationBtnAction = (params) => (dispatch) => {
  dispatch({
    type: EMAIL_CHAT_BUTTON,
    payload: params,
  })
}

export const submissionTableListAction = (params) => (dispatch) => {
  dispatch({
    type: SUBMISSION_LIST,
    payload: params,
  })
}

export const managerApprovalPermissionAction = (params) => (dispatch) => {
  dispatch({
    type: MANAGER_APPROVER_PERMISSIONS,
    payload: params,
  })
}
export const chartGPTSwitch = (params) => (dispatch) => {
  dispatch({
    type: CHAT_GPT_SWITCH,
    payload: params,
  })
}
