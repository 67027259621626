import React from 'react'
import Button from '@material-ui/core/Button'

const CustomButton = (props) => {
  const {
    variant,
    color,
    size,
    children,
    className,
    onClick,
    type,
    fullWidth,
    disabled,
    name,
  } = props
  return (
    <Button
      className={className}
      variant={variant}
      color={color}
      size={size}
      fullWidth={fullWidth}
      type={type}
      onClick={onClick}
      disabled={disabled}
      disableElevation
      name={name}
      id={name}
    >
      {children}
    </Button>
  )
}

export default CustomButton
