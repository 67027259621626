import React, { useEffect } from 'react'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import customDispatch from '../containers/customDispatch'
import { toastAction } from '../store/actions/app'
import { equal } from '../utils/javascript'

function ToastMsg({ type, msg }) {
  const { cusDispatch } = customDispatch()
  useEffect(() => {
    if (equal(type, 'error')) toast.error(msg, { className: 'alert-danger' })
    else toast.success(msg, { className: 'alert-success' })

    if (msg) cusDispatch(toastAction({}))
  }, [type, msg])
  return (
    <>
      <ToastContainer />
    </>
  )
}
export default ToastMsg
