import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core'
import { useSelector } from 'react-redux'
import changeRoute from '../../containers/changeRoute'
import uid from '../uid'
import { equal } from '../../utils/javascript'
import { useRouter } from '../../hooks/use-router'
import { decodeProjectToken, decodeToken } from '../../hooks/decodeToken'
import CustomTooltip from '../CustomTooltip'
import customDispatch from '../../containers/customDispatch'
import { sidebarTourAction } from '../../store/actions/app'
import {
  companyAdminCurrentPageAction,
  projectAdminCurrentPageAction,
  projectCurrentPageAction,
} from '../../store/actions/dataList'
import {
  setPageNumberAction,
  submissionActionType,
} from '../../store/actions/projectAdmin'
import { setAuditTabTypeActions } from '../../store/actions/reviewer'

const useStyles = makeStyles((theme) => ({
  sidebarMenu: {
    position: 'relative',
    paddingBottom: 20,
    marginBottom: 10,
    '&:not(:last-child):before': {
      content: "''",
      position: 'absolute',
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: '#fff',
      width: 20,
      margin: '0 auto',
      height: 1,
    },
    '& .MuiListItem-root': {
      margin: '10px 15px !important',
      width: 'auto',
      borderRadius: 8,
      paddingLeft: 14,
      color: '#fff',
      '& .MuiListItemIcon-root': {
        minWidth: 36,
        color: '#fff',
      },
      '&.Mui-selected, &:hover': {
        backgroundColor: '#fff',
        color: theme.palette.primary.main,
        '& .MuiListItemIcon-root': {
          color: theme.palette.primary.main,
        },
      },
    },
    '& .MuiListItemText-primary': {
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      fontSize: 17,
    },
  },
}))

const DrawerList = ({ list = [] }) => {
  const { role: companyRole } = decodeToken()
  const { role: projectRole } = decodeProjectToken()
  const { cusDispatch } = customDispatch()
  const classes = useStyles()
  const { route } = changeRoute()
  const router = useRouter()
  const sidebarTooltip = useSelector((state) => state.app.sidebar_tooltip)

  const setType = (type) => {
    if (equal(type, 'PROJECT_ADMIN') || equal(type, 'project_admin'))
      return 'Project Admin'
    if (equal(type, 'assistant') || equal(type, 'ASSISTANT')) return 'Assistant'
    if (equal(type, 'manager') || equal(type, 'MANAGER')) return 'Manager'
    if (equal(type, 'reviewer') || equal(type, 'REVIEWER')) return 'Reviewer'
    return type
  }

  const handleClick = (path, primary) => {
    cusDispatch(sidebarTourAction(primary))
    cusDispatch(
      projectAdminCurrentPageAction({
        teamMListCurrentPage: 1,
        costReportListCurrentPage: 1,
        auditViewCurrentPage: 1,
      }),

      cusDispatch(
        companyAdminCurrentPageAction({
          teamMListCurrentPage: 1,
        }),
      ),
      cusDispatch(projectCurrentPageAction(1)),
      cusDispatch(setPageNumberAction(1)),
      cusDispatch(submissionActionType('PURCHASE_ORDER')),
      cusDispatch(setAuditTabTypeActions('W-9s')),
    )
    route(path)
  }

  return (
    <List className={classes.sidebarMenu}>
      {list.map(({ path, icon, primary }) => (
        <ListItem
          key={uid()}
          button
          onClick={() => handleClick(path, primary)}
          selected={equal(router.pathname, path)}
        >
          {equal(primary, 'Project Dashboard') ? (
            projectRole ? (
              <>
                {!sidebarTooltip ? (
                  <CustomTooltip title={primary}>
                    <ListItemIcon>{icon}</ListItemIcon>
                  </CustomTooltip>
                ) : (
                  <ListItemIcon>{icon}</ListItemIcon>
                )}

                <ListItemText
                  primary={`${setType(projectRole)} Dashboard`}
                  name={primary}
                  id={primary}
                />
              </>
            ) : (
              <>
                {!sidebarTooltip ? (
                  <CustomTooltip title={primary}>
                    <ListItemIcon>{icon}</ListItemIcon>
                  </CustomTooltip>
                ) : (
                  <ListItemIcon>{icon}</ListItemIcon>
                )}

                <ListItemText
                  primary={`${setType(companyRole)} Dashboard`}
                  name={primary}
                  id={primary}
                />
              </>
            )
          ) : (
            <>
              {!sidebarTooltip ? (
                <CustomTooltip title={primary}>
                  <ListItemIcon>{icon}</ListItemIcon>
                </CustomTooltip>
              ) : (
                <ListItemIcon>{icon}</ListItemIcon>
              )}

              <ListItemText primary={primary} name={primary} id={primary} />
            </>
          )}
        </ListItem>
      ))}
    </List>
  )
}

export default DrawerList
