import {
  SA_COMPANY_LIST,
  SA_PROJECT_LIST,
  SA_USER_LIST,
  CA_TEAM_MEMBER_LIST,
  CA_COST_REPORT_LIST,
  ALL_TEAM_MEMBER,
  ALL_COST_REPORT_LIST,
  AUTOFILL_SUBMISSION,
  UPLOADED_LEDGER_DATA,
  AUDIT_W9_DATA,
  AUDIT_POP_DATA,
  AUDIT_INVOICE_DATA,
  COMPANY_CURRENT_PAGE,
  PROJECT_CURRENT_PAGE,
  USER_LIST_CURRENT_PAGE,
  COMPANY_ADMIN_CURRENT_PAGE,
  PROJECT_ADMIN_CURRENT_PAGE,
} from '../constants'

export const companyListAction = (params) => (dispatch) => {
  dispatch({
    type: SA_COMPANY_LIST,
    payload: params,
  })
}
export const projectListDataAction = (params) => (dispatch) => {
  dispatch({
    type: SA_PROJECT_LIST,
    payload: params,
  })
}
export const userListDataAction = (params) => (dispatch) => {
  dispatch({
    type: SA_USER_LIST,
    payload: params,
  })
}
export const teamMemberDataAction = (params) => (dispatch) => {
  dispatch({
    type: CA_TEAM_MEMBER_LIST,
    payload: params,
  })
}
export const costReportDataAction = (params) => (dispatch) => {
  dispatch({
    type: CA_COST_REPORT_LIST,
    payload: params,
  })
}
export const allTeamDataAction = (params) => (dispatch) => {
  dispatch({
    type: ALL_TEAM_MEMBER,
    payload: params,
  })
}
export const allCostReportDataAction = (params) => (dispatch) => {
  dispatch({
    type: ALL_COST_REPORT_LIST,
    payload: params,
  })
}
export const autoFillSubmissionDataAction = (params) => (dispatch) => {
  dispatch({
    type: AUTOFILL_SUBMISSION,
    payload: params,
  })
}
export const getUploadedDataAction = (params) => (dispatch) => {
  dispatch({
    type: UPLOADED_LEDGER_DATA,
    payload: params,
  })
}
export const auditW9Action = (params) => (dispatch) => {
  dispatch({
    type: AUDIT_W9_DATA,
    payload: params,
  })
}
export const auditPOPAction = (params) => (dispatch) => {
  dispatch({
    type: AUDIT_POP_DATA,
    payload: params,
  })
}
export const auditInvoiceAction = (params) => (dispatch) => {
  dispatch({
    type: AUDIT_INVOICE_DATA,
    payload: params,
  })
}
export const companyCurrentPageAction = (params) => (dispatch) => {
  dispatch({
    type: COMPANY_CURRENT_PAGE,
    payload: params,
  })
}
export const projectCurrentPageAction = (params) => (dispatch) => {
  dispatch({
    type: PROJECT_CURRENT_PAGE,
    payload: params,
  })
}
export const userListCurrentPageAction = (params) => (dispatch) => {
  dispatch({
    type: USER_LIST_CURRENT_PAGE,
    payload: params,
  })
}

export const companyAdminCurrentPageAction = (params) => (dispatch) => {
  dispatch({
    type: COMPANY_ADMIN_CURRENT_PAGE,
    payload: params,
  })
}

export const projectAdminCurrentPageAction = (params) => (dispatch) => {
  dispatch({
    type: PROJECT_ADMIN_CURRENT_PAGE,
    payload: params,
  })
}
