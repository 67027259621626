import { gql } from '@apollo/react-hooks'

export const GET_REVIEWER_NOTIFICATION_LIST = gql`
  query getNotificationList($projectId: String!) {
    getReviewerNotificationList(projectId: $projectId) {
      notificationList {
        notificationId
      }
      error
    }
  }
`
export const GET_REVIEWER_NOTIFICATION_COUNT = gql`
  query getNotificationCount($projectId: String!) {
    getReviewerNotificationCount(projectId: $projectId) {
      error
      notificationCount
    }
  }
`
