import { useDispatch } from 'react-redux'

const customDispatch = () => {
  const dispatch = useDispatch()
  const cusDispatch = (params) => {
    dispatch(params)
  }
  return { cusDispatch }
}

export default customDispatch
