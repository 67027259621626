import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { authAction, pathAction } from '../store/actions/app'
import { conduitRolesList } from '../utils/constants'
import { getPath, include, not } from '../utils/javascript'
import { isLoggedIn } from '../utils/localStorage'
import customDispatch from './customDispatch'
import defaultRole from './defaultRole'

const authentication = () => {
  const auth = useSelector((state) => state.app.auth)
  const authenticated = isLoggedIn()
  const { cusDispatch } = customDispatch()

  useEffect(() => {
    cusDispatch(pathAction(getPath))
  }, [])
  if (
    not(authenticated.loggedIn) ||
    not(include(conduitRolesList, authenticated.role))
  )
    defaultRole()
  // if (authenticated.loggedIn) cusDispatch(authAction(true))
  cusDispatch(authAction(authenticated.loggedIn))

  return { auth, authenticated }
}

export default authentication
