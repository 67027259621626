import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import CheckIcon from '@material-ui/icons/Check'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableRow from '@material-ui/core/TableRow'
import { useSelector } from 'react-redux'
import clsx from 'clsx'
import { useLocation } from 'react-router-dom'
import CustomButton from './CustomButton'
import verifyCompany, {
  approveRejectSubmission,
} from '../containers/verifyCompany'
import customDispatch from '../containers/customDispatch'
import { equal, ternary, length } from '../utils/javascript'
import dispatchToastMsg from '../containers/dispatchToastMsg'
import {
  approvedNotificationId,
  notificationStatusAction,
  thumbAction,
  notificationInfoAction,
} from '../store/actions/app'
import { submissionActionType } from '../store/actions/projectAdmin'
import { isLoggedIn } from '../utils/localStorage'
import {
  Approve,
  bellNotification,
  invoiceSummary,
  invoiceSummaryType,
  Reject,
  superAdminRole,
  thumbNotification,
  toastSuccess,
  reviewerRole,
} from '../utils/constants'
import { decodeProjectToken } from '../hooks/decodeToken'
import { CapitalizeSubmissions } from '../utils/customFunction'
import changeRoute from '../containers/changeRoute'

const useStyles = makeStyles((theme) => ({
  DialogHeading: {
    padding: [[20, 24, 16]],
    '& .MuiTypography-root': {
      fontSize: '20px !important',
      color: '#1D273F',
      fontWeight: '900',
      display: 'flex',
      justifyContent: 'space-between',
      '& svg': {
        fontSize: 25,
      },
    },
  },
  table: {
    fontSize: 16,
    fontWeight: '500',
    '& tr': {
      '&:last-child': {
        '& td': {
          border: 'none',
        },
      },
      '& td': {
        padding: [[6, 8]],
        fontSize: 12,
        fontWeight: '500',
        color: '#454545',
        textAlign: 'left',
        borderBottom: 'none !important',
        '& p': {
          display: 'none',
          fontWeight: '600',
        },
        '&:last-child': {
          borderRadius: 0,
          textAlign: 'center',
        },
        '&:nth-last-child(2)': {
          textAlign: 'center !important',
        },
      },
      '& svg': {
        verticalAlign: 'middle',
      },
      '& button': {
        padding: [[0, 10]],
        fontSize: '12px !important',
      },
    },
  },
  ApprovedNotification: {
    borderRadius: 0,
    background: 'rgb(15 , 159 ,91 , 0.10)',
    '& td': {
      color: '#106c24 !important',
      border: 'none',
      borderBottom: 'none !important',
      overflow: 'hidden',
      maxWidth: 200,
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      '& button': {
        display: 'none',
      },
      '& p': {
        display: 'block !important',
        width: '100%',
        textAlign: 'center',
      },
      '&:first-child': {
        borderRadius: 0,
        borderLeft: '4px solid #0f9f5b',
      },
      '&:last-child': {
        borderRadius: 0,
        '& svg': {
          visibility: 'hidden',
        },
      },
    },
  },
  RejectedNotification: {
    borderRadius: 0,
    background: 'rgba(227 ,66 ,66, 0.1)',

    '& td': {
      color: '#e02d2d !important',
      border: 'none',
      textAlign: 'center',
      borderBottom: 'none !important',
      '& button': {
        display: 'none',
      },
      '& p': {
        display: 'block !important',
        width: '100%',
        textAlign: 'center',
      },
      '&:first-child': {
        borderRadius: 0,
        borderLeft: '3px solid #e02d2d',
      },
      '&:last-child': {
        borderRadius: 0,
        '& svg': {
          visibility: 'hidden',
        },
      },
    },
  },
  spacingBottom: {
    border: 'none !important',
    padding: '0 !important',
    height: 4,
    lineHeight: '0',
  },
  DialogContent: {
    padding: theme.spacing(0, 3),
  },
  DialogContentText: {
    fontSize: '14px !important',
    color: '#1D273F',
  },
  btnActionPadding: {
    padding: theme.spacing(2),
    '& .MuiButton-root': {
      paddingTop: 6,
      paddingBottom: 6,
    },
  },
  approve: {
    backgroundColor: 'transparent',
    color: '#0f5132',
    border: '1px solid #badbcc',
    borderRadius: 100,
    '&:hover': {
      backgroundColor: '#e7f5ee !important',
      color: '#106c24 !important',
      borderColor: 'transparent',
    },
  },
  cancel: {
    backgroundColor: 'transparent',
    color: '#842029',
    border: '1px solid #f5c2c7',
    borderRadius: 100,
    '&:hover': {
      backgroundColor: '#f0c5c9  !important',
      color: '#842029  !important',
      borderColor: 'transparent',
    },
  },
}))

const NotificationDialogue = React.forwardRef(
  ({ content, onClose, handleClose }) => {
    const [notificationListData, setNotificationListData] = useState([])
    const classes = useStyles()
    const { toast } = dispatchToastMsg()
    const { role } = isLoggedIn()
    const { role: projectRole } = decodeProjectToken()
    const { handleSubmit, data } = verifyCompany()
    const { cusDispatch } = customDispatch()
    const [id, setId] = useState('')
    const approverUser = useSelector(
      (state) => state.projectAdmin.approver_user,
    )

    const { route } = changeRoute()
    const { pathname } = useLocation()
    const projectDetailId = pathname.split('/')[2]
    const { approvalAction } = approveRejectSubmission()
    const approvedNotificationIndex = useSelector(
      (state) => state.app.approved_notification_id,
    )
    const notificationInfo = useSelector((state) => state.app.notification_info)

    const notificationType = useSelector(
      (state) => state.projectAdmin.notification_type,
    )

    const setReviewerListDetail = (formName, submissionId) => {
      if (equal(role, reviewerRole)) {
        cusDispatch(notificationInfoAction(''))
        cusDispatch(submissionActionType(formName))
      }
      handleClose()
      onClose()
      route(
        `/project-details/${projectDetailId}/submission-detail/${formName}/${submissionId}`,
      )
    }

    useEffect(() => {
      if (content.length) {
        setNotificationListData(content)
      }
    }, [content])

    useEffect(() => {
      const response = data?.company?.companyVerification
      const success = response?.success
      const status = response?.status
      if (
        success &&
        (equal(notificationInfo, thumbNotification) || equal(status, Reject))
      ) {
        cusDispatch(thumbAction(id))
        cusDispatch(notificationStatusAction(status))
      }
      if (success)
        toast(
          toastSuccess,
          ternary(
            equal(status, Reject),
            'Rejected Successfully',
            'Approved Successfully',
          ),
        )
    }, [data])

    return ternary(
      equal(role, superAdminRole),
      <TableContainer>
        {ternary(
          length(content),
          <Table className={classes.table} aria-label="simple table">
            <TableBody>
              {content.map((row, index) => (
                <React.Fragment key={index}>
                  <TableRow
                    key={row.companyName}
                    className={clsx(null, {
                      [classes.ApprovedNotification]:
                        (equal(approvedNotificationIndex, index) &&
                          equal(notificationInfo, thumbNotification)) ||
                        (equal(notificationInfo, bellNotification) &&
                          equal(row.status, Approve)) ||
                        equal(approvedNotificationIndex, index),
                      [classes.RejectedNotification]: equal(row.status, Reject),
                    })}
                  >
                    <TableCell>{row.companyName}</TableCell>
                    <TableCell align="right">{row.companyEmail}</TableCell>
                    <TableCell align="right">
                      <CustomButton
                        variant="contained"
                        color="primary"
                        disabled={equal(row.status, Reject)}
                        onClick={() => {
                          setTimeout(() => {
                            setId(row.id)
                            handleSubmit(row.id, Approve)
                          }, 3000)
                          cusDispatch(approvedNotificationId(index))
                        }}
                      >
                        APPROVE
                      </CustomButton>
                      {equal(row.status, Approve) && (
                        <p>
                          <CheckIcon />
                        </p>
                      )}
                      {equal(row.status, Reject) && (
                        <p>
                          <CloseIcon />
                        </p>
                      )}
                    </TableCell>
                    <TableCell>
                      <CloseIcon
                        onClick={() => {
                          setId(row.id)
                          handleSubmit(row.id, Reject)
                        }}
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <p className={classes.spacingBottom}></p>
                    </TableCell>
                  </TableRow>
                </React.Fragment>
              ))}
            </TableBody>
          </Table>,
          'Nothing to show here',
        )}
      </TableContainer>,
      ternary(
        equal(projectRole, 'REVIEWER'),
        <TableContainer>
          {ternary(
            notificationListData.length > 0,
            <Table className={classes.table} aria-label="simple table">
              <TableBody>
                {notificationListData.map((row, index) => {
                  const listData = row?.notificationId
                  return (
                    <React.Fragment key={index}>
                      <TableRow key={index}>
                        {equal(notificationType, bellNotification) && (
                          <>
                            <TableCell>
                              {listData?.formName
                                ? CapitalizeSubmissions(listData?.formName)
                                : ''}
                            </TableCell>
                            <TableCell>
                              {listData?.commitment
                                ? listData?.commitment
                                : listData?.amount
                                ? listData?.amount
                                : ''}
                            </TableCell>
                            <TableCell>{listData?.description || ''}</TableCell>
                            <TableCell>{listData?.vendorName || ''}</TableCell>
                            <TableCell>
                              <CustomButton
                                variant="contained"
                                className={classes.approve}
                                onClick={() =>
                                  setReviewerListDetail(
                                    listData?.formName,
                                    listData?.id,
                                  )
                                }
                              >
                                View
                              </CustomButton>
                            </TableCell>
                          </>
                        )}
                      </TableRow>
                    </React.Fragment>
                  )
                })}
              </TableBody>
            </Table>,
            'Nothing to show here',
          )}
        </TableContainer>,
        <TableContainer>
          {ternary(
            content.length > 0 && approverUser,
            <Table className={classes.table} aria-label="simple table">
              <TableBody>
                {content.map((row, index) => (
                  <React.Fragment key={index}>
                    <TableRow
                      key={index}
                      className={clsx(null, {
                        [classes.ApprovedNotification]:
                          (equal(approvedNotificationIndex, index) &&
                            equal(notificationInfo, thumbNotification)) ||
                          equal(row.status, Approve) ||
                          equal(approvedNotificationIndex, index),
                        [classes.RejectedNotification]: equal(
                          row.status,
                          Reject,
                        ),
                      })}
                    >
                      {equal(notificationType, bellNotification) && (
                        <>
                          <TableCell>{row?.notificationType}</TableCell>
                          <TableCell align="right">{row?.vendorName}</TableCell>
                        </>
                      )}
                      {equal(notificationType, thumbNotification) && (
                        <>
                          <TableCell>
                            {ternary(
                              equal(row?.formName, invoiceSummary),
                              invoiceSummaryType,
                              row?.formName,
                            )}
                          </TableCell>
                          <TableCell align="right">{row?.amount}</TableCell>
                        </>
                      )}
                      <TableCell align="center">
                        {ternary(
                          equal(row?.status, Approve),
                          <p>Approved</p>,
                          ternary(
                            equal(row?.status, Reject),
                            <p>Rejected</p>,
                            <CustomButton
                              variant="contained"
                              className={classes.approve}
                              onClick={() =>
                                approvalAction(
                                  Approve,
                                  row.id,
                                  equal(notificationType, bellNotification)
                                    ? row?.notificationType
                                    : row?.formName,
                                )
                              }
                            >
                              APPROVE
                            </CustomButton>,
                          ),
                        )}
                      </TableCell>

                      <TableCell>
                        <CustomButton
                          variant="contained"
                          className={classes.cancel}
                          onClick={() =>
                            approvalAction(
                              Reject,
                              row.id,
                              equal(notificationType, bellNotification)
                                ? row?.notificationType
                                : row?.formName,
                            )
                          }
                        >
                          Reject
                        </CustomButton>
                      </TableCell>
                      {equal(notificationType, thumbNotification) && (
                        <>
                          <TableCell>
                            {' '}
                            <CustomButton
                              variant="contained"
                              className={classes.approve}
                              onClick={() =>
                                setReviewerListDetail(row?.formName, row?.id)
                              }
                            >
                              View
                            </CustomButton>
                          </TableCell>
                        </>
                      )}
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <p className={classes.spacingBottom}></p>
                      </TableCell>
                    </TableRow>
                  </React.Fragment>
                ))}
              </TableBody>
            </Table>,
            `${
              approverUser
                ? 'Nothing to show here Please select project'
                : 'Nothing to show here '
            }`,
          )}
        </TableContainer>,
      ),
    )
  },
)

export default NotificationDialogue
