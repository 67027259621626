// eslint-disable-next-line camelcase
import jwt_decode from 'jwt-decode'
import { commonRole } from '../utils/constants'
import { lowerCase } from '../utils/javascript'
import { isLoggedIn } from '../utils/localStorage'

export const decodeProjectToken = () => {
  const { projectToken } = isLoggedIn()

  let decoded
  if (projectToken) {
    decoded = jwt_decode(projectToken)
  }

  const projectId = decoded?.projectId || ''
  const role = decoded?.role || ''
  const userId = decoded?._id
  const projectName = decoded?.projectName

  return { projectId, role, userId, projectName }
}

export const decodeToken = () => {
  const { token } = isLoggedIn()

  let decoded
  if (token) {
    decoded = jwt_decode(token)
  }

  const userId = decoded?._id
  const firstName = decoded?.firstName
  const companyId = decoded?.companyId
  const projectId = decoded?.projectId
  const role = lowerCase(decoded?.role || commonRole)
  const companyProfile = decoded?.companyProfile

  return { userId, firstName, companyId, projectId, role, companyProfile }
}
