import { useSelector } from 'react-redux'
import customDispatch from './customDispatch'
import { notificationInfoAction } from '../store/actions/app'
import { setNotificationTypeAction } from '../store/actions/projectAdmin'

const bellThumbNotificationInfo = () => {
  const { cusDispatch } = customDispatch()
  const notificationType = useSelector((state) => state.app.notification_info)

  const checkThumbNotification = () => {
    cusDispatch(notificationInfoAction('thumbNotification'))
    cusDispatch(setNotificationTypeAction('thumbNotification'))
  }

  const checkBellNotification = () => {
    cusDispatch(notificationInfoAction('bellNotification'))
    cusDispatch(setNotificationTypeAction('bellNotification'))
  }
  const cancelNotification = () => {
    cusDispatch(notificationInfoAction(''))
    cusDispatch(setNotificationTypeAction(''))
  }
  return [
    {
      checkBellNotification,
      checkThumbNotification,
      notificationType,
      cancelNotification,
    },
  ]
}
export default bellThumbNotificationInfo
