import {
  AUTH,
  DRAWER,
  PATH,
  THUMB,
  TOAST,
  UPDATE,
  COMP_DATA,
  DELETE_ID,
  NOTIFICATION_INFO,
  COST_REPORT_DATE,
  PROJECT_ID,
  UPDATE_CONFORMATION,
  LOGOUT,
  PROJECT_ADMIN_ROLE,
  APPBAR_DRAWER,
  COMPANY_ROLE,
  APPROVED_NOTIFICATION_ID,
  NOTIFICATION_STATUS,
  CA_SWITCH,
  SIDEBAR_TOOLTIP,
  SIDEBAR_TOUR,
  DASHBOARD_TOUR,
  TOUR_STATUS,
} from '../constants'

const initialState = {
  auth: false,
  open: true,
  path: '/',
  approveCompany_id: '',
  toast: {},
  update: '',
  compDat: [],
  delete_id: '',
  notification_info: '',
  cost_report_date: '',
  project_id: '',
  update_conformation: '',
  logout: false,
  project_role: false,
  toggle_drawer: true,
  company_role: 'common',
  approved_notification_id: '',
  notification_status: '',
  switch: {},
  sidebar_tooltip: true,
  sidebar_type: '',
  dashboard_tour: {},
  tourStatus: true,
}

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case AUTH:
      return {
        ...state,
        auth: payload,
      }
    case DRAWER:
      return {
        ...state,
        open: payload,
      }

    case PATH:
      return {
        ...state,
        path: payload,
      }
    case THUMB:
      return {
        ...state,
        approveCompany_id: payload,
      }
    case TOAST:
      return {
        ...state,
        toast: payload,
      }
    case UPDATE:
      return {
        ...state,
        update: payload,
      }
    case COMP_DATA:
      return {
        ...state,
        compDat: payload,
      }
    case DELETE_ID:
      return {
        ...state,
        delete_id: payload,
      }
    case NOTIFICATION_INFO:
      return {
        ...state,
        notification_info: payload,
      }
    case COST_REPORT_DATE:
      return {
        ...state,
        cost_report_date: payload,
      }
    case PROJECT_ID:
      return {
        ...state,
        project_id: payload,
      }
    case UPDATE_CONFORMATION:
      return {
        ...state,
        update_conformation: payload,
      }
    case LOGOUT:
      return {
        ...state,
        logout: payload,
      }
    case PROJECT_ADMIN_ROLE:
      return {
        ...state,
        project_role: payload,
      }
    case APPBAR_DRAWER:
      return {
        ...state,
        toggle_drawer: payload,
      }
    case COMPANY_ROLE:
      return {
        ...state,
        company_role: payload,
      }
    case APPROVED_NOTIFICATION_ID:
      return {
        ...state,
        approved_notification_id: payload,
      }
    case NOTIFICATION_STATUS:
      return {
        ...state,
        notification_status: payload,
      }
    case CA_SWITCH:
      return {
        ...state,
        switch: payload,
      }
    case SIDEBAR_TOOLTIP:
      return {
        ...state,
        sidebar_tooltip: payload,
      }
    case SIDEBAR_TOUR:
      return {
        ...state,
        sidebar_type: payload,
      }
    case DASHBOARD_TOUR:
      return {
        ...state,
        dashboard_tour: payload,
      }

    case TOUR_STATUS:
      return {
        ...state,
        tourStatus: payload,
      }
    default:
      return state
  }
}
