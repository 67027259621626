import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { GET_PENDING_NOTIFICATION_LIST } from '../graphQL/queries/query'
import { equal, notEqual } from '../utils/javascript'
import {
  approvedNotificationId,
  // notificationInfoAction,
  thumbAction,
} from '../store/actions/app'
import customDispatch from './customDispatch'
import {
  companyProjectRole,
  superAdminRole,
  thumbNotification,
} from '../utils/constants'
import { reusableLazyQuery } from './commonFunctions'
import { GET_SUBMISSION_THUMB_NOTIFICATION } from '../graphQL/queries/submission'
import { submissionThumbIdAction } from '../store/actions/projectAdmin'
import { decodeProjectToken, decodeToken } from '../hooks/decodeToken'

const getPendingCompanyList = ({ setNotificationCount, notificationCount }) => {
  const [thumbData, setThumbData] = useState([])
  const { cusDispatch } = customDispatch()
  const { role } = decodeToken()
  const { projectId, role: projectRole } = decodeProjectToken()
  const thumbNotify = useSelector((state) => state.app.approveCompany_id)
  const notificationInfo = useSelector((state) => state.app.notification_info)
  const thumbId = useSelector((state) => state.projectAdmin.thumb_id)
  const notificationType = useSelector((state) => state.app.notification_info)
  const managerThumbPer = useSelector(
    (state) => state.projectAdmin.manager_thumb_per,
  )
  const [getThumbNotification, { loading, data }] = reusableLazyQuery(
    GET_PENDING_NOTIFICATION_LIST,
  )
  const [thumbNotificationLBadge, { data: submissionThumb }] =
    reusableLazyQuery(GET_SUBMISSION_THUMB_NOTIFICATION)

  useEffect(() => {
    if (
      (equal(projectRole, companyProjectRole) ||
        equal(projectRole, 'MANAGER')) &&
      notEqual(projectId, '')
    ) {
      thumbNotificationLBadge({
        variables: {
          ...{ projectId },
        },
      })
    }

    // cusDispatch(notificationInfoAction(''))
  }, [notificationInfo, projectId])

  useEffect(() => {
    if (
      equal(role, superAdminRole) &&
      equal(notificationType, thumbNotification)
    ) {
      getThumbNotification()
    }
  }, [notificationType])

  useEffect(() => {
    const responseData =
      submissionThumb?.getSubmissionNotificationList?.submissionData
    setThumbData(responseData)
  }, [submissionThumb])

  useEffect(() => {
    if (thumbId && managerThumbPer?.success) {
      const updatedItems = thumbData.filter((elem) =>
        notEqual(elem.id, thumbId),
      )
      setThumbData(updatedItems)
      if (notEqual(notificationCount.thumbCount, 0)) {
        setNotificationCount({
          ...notificationCount,
          thumbCount: notificationCount.thumbCount - 1,
        })
        cusDispatch(submissionThumbIdAction(''))
      }
    }
  }, [thumbId, managerThumbPer])

  useEffect(() => {
    if (equal(role, superAdminRole)) {
      setThumbData(data?.getCompanyList?.companyList)
    }
  }, [data])

  useEffect(() => {
    if (thumbNotify && equal(notificationInfo, thumbNotification)) {
      const updatedItems = thumbData.filter((elem) =>
        notEqual(elem.id, thumbNotify),
      )
      setThumbData(updatedItems)
      cusDispatch(thumbAction(''))
      if (equal(notificationInfo, thumbNotification)) {
        cusDispatch(approvedNotificationId(''))
      }
    }
  }, [thumbNotify])

  return [{ thumbData, loading }]
}

export default getPendingCompanyList
