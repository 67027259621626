import { lazy } from 'react'

const Home = lazy(() => import('../presentation/Home'))
// const CreateCompany = lazy(() =>
//   import('../presentation/CreateCompanyPresentation'),
// )
const Login = lazy(() => import('../presentation/Login'))
const SubmissionMail = lazy(() => import('../presentation/SubmissionMailMsg'))
const ForgotPasswordEmail = lazy(() =>
  import('../presentation/ForgotPasswordEmail'),
)
const TemConditions = lazy(() => import('../presentation/TermConditions'))
const PrivacyPolicy = lazy(() => import('../presentation/PrivacyPolicy'))
const ForgotPassword = lazy(() => import('../presentation/ForgotPassword'))
const NetworkError = lazy(() => import('../presentation/NetworkError'))

const redirectUrl = '/dashboard'

export default [
  {
    path: ['/', '/home'],
    component: Home,
    exact: true,
    redirectUrl,
  },
  // {
  //   path: '/create-company',
  //   component: CreateCompany,
  // },
  {
    path: '/login',
    component: Login,
    redirectUrl,
  },
  {
    path: '/forgot-password',
    component: ForgotPasswordEmail,
    redirectUrl,
  },
  {
    path: '/term-conditions',
    component: TemConditions,
    redirectUrl,
  },
  {
    path: '/privacy-policy',
    component: PrivacyPolicy,
    redirectUrl,
  },
  {
    path: '/forgotPassword',
    component: ForgotPassword,
    redirectUrl,
  },
  {
    path: '/submissionmail',
    component: SubmissionMail,
    // redirectUrl,
  },
  {
    path: '/network-error',
    component: NetworkError,
    redirectUrl,
  },
  {
    path: '/network-error',
    component: NetworkError,
    redirectUrl,
  },
  {
    path: '/network-error',
    component: NetworkError,
    redirectUrl,
  },
  {
    path: '/network-error',
    component: NetworkError,
  },
]
