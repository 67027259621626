import { lazy } from 'react'
import hoc from '../hoc'

const SuperAdminDashboard = lazy(() =>
  import('../presentation/SuperAdminDashboard'),
)
const CreateProject = lazy(() =>
  import('../presentation/SuperAdminDashboard/SuperAdminCreateProject'),
)
const AddCompany = lazy(() =>
  import('../presentation/SuperAdminDashboard/SuperAdminAddCompany'),
)
const AddUser = lazy(() =>
  import('../presentation/SuperAdminDashboard/SuperAdminAddUser'),
)
const ViewCompanies = lazy(() =>
  import('../presentation/SuperAdminDashboard/CompanyList'),
)
const ViewProjects = lazy(() =>
  import('../presentation/SuperAdminDashboard/ViewProjects'),
)
const ViewUsers = lazy(() =>
  import('../presentation/SuperAdminDashboard/ViewUsers'),
)
// const ViewVendors = lazy(() =>
//   import('../presentation/SuperAdminDashboard/ViewVendors'),
// )
const ViewCompanyDetails = lazy(() =>
  import('../presentation/SuperAdminDashboard/CompanyDetails'),
)
const ViewProjectDetails = lazy(() =>
  import('../presentation/SuperAdminDashboard/ProjectDetails'),
)
const ViewUserDetails = lazy(() =>
  import('../presentation/SuperAdminDashboard/UserDetails'),
)
const UserProfile = lazy(() => import('../presentation/UserProfile'))
const redirectUrl = '/login'
export default [
  {
    path: '/dashboard',
    component: hoc(SuperAdminDashboard),
    auth: true,
    redirectUrl,
  },
  {
    path: '/create-project',
    component: hoc(CreateProject),
    auth: true,
    redirectUrl,
  },
  {
    path: '/add-company',
    component: hoc(AddCompany),
    auth: true,
    redirectUrl,
  },
  {
    path: '/add-user',
    component: hoc(AddUser),
    auth: true,
    redirectUrl,
  },
  {
    path: '/view-companies',
    component: hoc(ViewCompanies),
    auth: true,
    redirectUrl,
  },
  {
    path: '/view-projects',
    component: hoc(ViewProjects),
    auth: true,
    redirectUrl,
  },
  {
    path: '/view-users',
    component: hoc(ViewUsers),
    auth: true,
    redirectUrl,
  },
  // {
  //   path: '/view-vendors',
  //   component: hoc(ViewVendors),
  //   auth: true,
  //   redirectUrl,
  // },
  {
    path: '/company-details/:id',
    component: hoc(ViewCompanyDetails),
    auth: true,
    redirectUrl,
  },
  {
    path: '/project-details/:id',
    component: hoc(ViewProjectDetails),
    auth: true,
    redirectUrl,
  },
  {
    path: '/user-details/:id',
    component: hoc(ViewUserDetails),
    auth: true,
    redirectUrl,
  },
  {
    path: '/user-profile',
    component: hoc(UserProfile),
    auth: true,
    redirectUrl,
  },
]
