import {
  DELETE_ADDED_LEDGER_FILE,
  CALL_LEDGER,
  UPDATE_AUDIT,
  VIEW_AUDIT_DETAIL,
  REVIEWER_STATUS_FINANCE,
  QUICK_BOOK_PAGE_NO,
  QUICK_BOOK_SUBMISSION_TYPE,
  PREVIOUS_VENDOR_DETAILS,
  ADD_NOTES,
  EXPORT_COST_REPORT,
  DELETE_FILTER_TEMPLATE_ID,
  APPLY_FILTER_TEMPLATE_ID,
} from '../constants'

const initialState = {
  delete_ledger_file: '',
  call_ledger: false,
  audit_update: {},
  audit_detail: {},
  reviewer_finance_status: '',
  auditType: '',
  QBPageNo: 1,
  QBSubmission_type: 'Configuration',
  previousVendorDetail: {},
  add_notes: false,
  export_cost_report: false,
  delete_filter_template_id: '',
  apply_filter_template_id: '',
}

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case DELETE_ADDED_LEDGER_FILE:
      return {
        ...state,
        delete_ledger_file: payload,
      }
    case CALL_LEDGER:
      return {
        ...state,
        call_ledger: payload,
      }
    case UPDATE_AUDIT:
      return {
        ...state,
        audit_update: payload,
      }
    case VIEW_AUDIT_DETAIL:
      return {
        ...state,
        audit_detail: payload,
      }
    case REVIEWER_STATUS_FINANCE:
      return {
        ...state,
        reviewer_finance_status: payload,
      }

    case 'AUDIT_TAB_TYPE':
      return {
        ...state,
        auditType: payload,
      }
    case QUICK_BOOK_PAGE_NO:
      return {
        ...state,
        QBPageNo: payload,
      }
    case QUICK_BOOK_SUBMISSION_TYPE:
      return {
        ...state,
        QBSubmission_type: payload,
      }
    case PREVIOUS_VENDOR_DETAILS:
      return {
        ...state,
        previousVendorDetail: payload,
      }
    case ADD_NOTES:
      return {
        ...state,
        add_notes: payload,
      }
    case EXPORT_COST_REPORT:
      return {
        ...state,
        export_cost_report: payload,
      }
    case DELETE_FILTER_TEMPLATE_ID:
      return {
        ...state,
        delete_filter_template_id: payload,
      }
    case APPLY_FILTER_TEMPLATE_ID:
      return {
        ...state,
        apply_filter_template_id: payload,
      }
    default:
      return state
  }
}
