import { useSelector } from 'react-redux'
import { drawerAction } from '../store/actions/app'
import customDispatch from './customDispatch'

const drawer = () => {
  const open = useSelector((state) => state.app.open)
  const { cusDispatch } = customDispatch()
  const drawerFn = (params) => {
    cusDispatch(drawerAction(params))
  }
  return { open, drawerFn }
}

export default drawer
