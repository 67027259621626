import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { ClearCacheProvider } from 'react-clear-cache'
import App from './App'
import reportWebVitals from './reportWebVitals'
import store from './store'

ReactDOM.render(
  <React.StrictMode>
    {process.env.NODE_ENV === 'production' ? (
      <ClearCacheProvider duration={5000}>
        <Provider store={store}>
          <App />
        </Provider>
      </ClearCacheProvider>
    ) : (
      <Provider store={store}>
        <App />
      </Provider>
    )}
  </React.StrictMode>,
  document.getElementById('root'),
)

reportWebVitals()
