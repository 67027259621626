import { lazy } from 'react'

const NotFound = lazy(() => import('../presentation/NotFound'))

export default [
  {
    path: '*',
    component: NotFound,
  },
]
