import { Redirect, Route, Switch } from 'react-router-dom'
import uid from '../shared/uid'

const SelectComponent = (
  props,
  Component,
  redirectUrl,
  auth,
  authenticated,
) => {
  const FinalComponent = <Component {...props} />
  const FinalRedirectComponent = redirectUrl ? (
    <Redirect to={{ pathname: redirectUrl }} />
  ) : (
    FinalComponent
  )

  if (auth) {
    if (authenticated.loggedIn) {
      return FinalComponent
    }
    return FinalRedirectComponent
  }

  if (authenticated.loggedIn) {
    return FinalRedirectComponent
  }
  return FinalComponent
}

const Index = ({ routes = [], authenticated }) => (
  <Switch>
    {routes.map(({ component: Component, redirectUrl, auth, ...route }) => (
      <Route
        key={uid()}
        {...route}
        render={(props) =>
          SelectComponent(props, Component, redirectUrl, auth, authenticated)
        }
      />
    ))}
  </Switch>
)

export default Index
