import { equal, include, length, ternary } from './javascript'
import {
  emailValidation,
  mobileNumber,
  // noSpace,
  number,
  stringValue,
  alphabeticStringUseUnderscoreValidation,
} from './regex'

export const nullValue = (value) =>
  ternary(!value || value.trim() === '', true, false)

const nameValidation = (value, msg) => {
  if (nullValue(value)) {
    return 'Is required!'
  }
  if (!stringValue(value)) {
    return `Please enter valid ${msg}`
  }
  return false
}
const projectNameValidation = (value, msg) => {
  if (nullValue(value)) {
    return 'Is required!'
  }
  if (!alphabeticStringUseUnderscoreValidation(value)) {
    return `Please enter valid ${msg}`
  }
  return false
}
const userNameValidation = (value, msg) => {
  if (nullValue(value)) {
    return 'Is required!'
  }
  // if (!noSpace(value)) {
  //   return 'First letter must be capital and Space is not allowed!'
  // }
  // if (!noSpace(value)) {
  //   return 'Space is not allowed!'
  // }
  if (!stringValue(value)) {
    return `Please enter valid ${msg}`
  }
  return false
}

const emailVal = (value, msg) => {
  if (nullValue(value)) {
    return 'Is required!'
  }
  if (!emailValidation(value)) {
    return `Enter a valid ${msg}`
  }
  return false
}

const exportEmailVal = (value, msg) => {
  if (nullValue(value)) {
    return `${msg} is required!`
  }
  if (!emailValidation(value)) {
    return `Enter a valid ${msg}`
  }
  return false
}

const noteVal = (value, msg) => {
  if (nullValue(value)) {
    return `${msg} is required!`
  }

  return false
}

const passwordValidation = (value, type) => {
  switch (true) {
    case nullValue(value):
      return 'Is required!'

    case include(value, ' '):
      return 'Password cannot contain spaces'
    case equal(type, 'New') && length(value) < 6:
      return 'Password must be at least 6 characters'
    default:
      return false
  }
}
let pass = ''
let password = ''
const formValidate = (name, value) => {
  if (name === 'newPassword') {
    pass = value
  }
  if (name === 'password') {
    password = value
  }

  switch (name) {
    case 'firstName':
      return userNameValidation(value, 'First Name')

    case 'lastName':
      return userNameValidation(value, 'Last Name')

    case 'projectName':
      return projectNameValidation(value, 'Project Name')

    case 'department':
      return nameValidation(value, 'Department')

    case 'companyName':
      return nameValidation(value, 'Company Name')

    case 'network':
      return nameValidation(value, 'Network')

    case 'email':
      return emailVal(value, 'Email')

    case 'companyEmail':
      return emailVal(value, 'Email')

    case 'companyAdminEmail':
      return emailVal(value, 'Email')

    case 'exportEmail':
      return exportEmailVal(value, 'Email address')

    case 'projectAdminEmail':
      return emailVal(value, 'Email')

    case 'companyContact':
      if (nullValue(value)) {
        return 'Is required!'
      }
      if (!mobileNumber(value)) {
        return 'Phone number must be 10 digits long!'
      }
      return false

    case 'password':
      password = value
      return passwordValidation(value, '')

    case 'confirmPassword':
      if (!value) {
        return 'Is required!'
      }
      if (value !== password) {
        return 'New password and Confirm password are not same!'
      }
      return false

    case 'oldPassword':
      return passwordValidation(value, 'Old')

    case 'newPassword':
      pass = value
      return passwordValidation(value, 'New')

    case 'confPassword':
      if (!value) {
        return 'Is required!'
      }
      if (value !== pass) {
        return 'New password and Confirm password are not same!'
      }
      return false

    case 'companyAddress':
      if (nullValue(value)) {
        return 'Is required!'
      }
      return false

    case 'companyId':
      if (nullValue(value)) {
        return 'Is required!'
      }
      return false

    case 'projectId':
      if (nullValue(value)) {
        return 'Is required!'
      }
      return false
    case 'approverId':
      if (nullValue(value)) {
        return 'Is required!'
      }
      return false
    case 'isApprover':
      if (nullValue(value)) {
        return 'Is required!'
      }
      return false
    case 'maxAmount':
      if (nullValue(value)) {
        return 'Is required!'
      }
      return false

    case 'role':
      if (nullValue(value)) {
        return 'Is required!'
      }
      return false
    case 'file':
      if (!value) {
        return 'Is required!'
      }
      return false
    case 'additional_notes':
      if (nullValue(value)) {
        return 'Is required!'
      }
      return false
    case 'openAiKey':
      if (nullValue(value)) {
        return 'Is required!'
      }
      return false

    case 'budget':
      if (value === '') {
        return 'Is required!'
      }
      if (!number(value)) {
        return 'Enter number only'
      }
      return false

    case 'note':
      if (value?.length > 500) {
        return 'Max characters limit exceeded!'
      }
      return false

    case 'updateNote':
      return noteVal(value, 'Note')

    case 'filterName':
      if (nullValue(value)) {
        return 'Is required!'
      }
      return false

    case 'filterTemplateSelect':
      if (value?.length === 0) {
        return 'Is required!'
      }
      return false

    default: {
      return ''
    }
  }
}

export default formValidate
