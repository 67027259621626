import React from 'react'
import { Tooltip } from '@material-ui/core'

const CustomTooltip = ({ title, children, className, classes }) => (
  <Tooltip className={className} classes={classes} title={title} arrow>
    <span>{children}</span>
  </Tooltip>
)

export default CustomTooltip
