import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Drawer from '@material-ui/core/Drawer'
import IconButton from '@material-ui/core/IconButton'
import clsx from 'clsx'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import DrawerList from './DrawerList'
import { drawerWidth } from '../../utils/constants'
import { head, last } from '../../utils/javascript'
import Scrollbar from '../ScrollBar'
import drawer from '../../containers/drawer'
import { default as projectAdminList } from '../../routing/projectDrawerList'
import authentication from '../../containers/authentication'
import customLogo from '../../assets/images/logos/conduiitWhite.svg'
import logoIcon from '../../assets/images/logos/conduiitWTIcon.svg'
import { sidebarAction, toggleDrawerAction } from '../../store/actions/app'
import customDispatch from '../../containers/customDispatch'
import { decodeProjectToken } from '../../hooks/decodeToken'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  drawerWrap: {
    '& .MuiPaper-root': {
      border: 0,
      backgroundColor: 'rgba(0,0,0,0)',
      backgroundImage: 'linear-gradient(#4030AB, #8055E9)',
    },
    '& .MuiListItemText-root': {
      whiteSpace: 'nowrap',
      color: theme.palette.common.main,
    },
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(13) + 1,
    },
    '& .MuiListItemText-root': {
      visibility: 'hidden',
      display: 'none',
    },
    '& .MuiListItemIcon-root': {
      minWidth: 'unset !important',
      width: 'fit-content',
      margin: '4px 0',
      display: 'block',
      '& svg': {
        verticalAlign: 'text-top',
      },
    },
    '& .MuiListItem-root': {
      width: 'fit-content',
    },
  },
  toolbar: {
    paddingRight: 24,
    justifyContent: 'flex-end',
  },
  toolbarIcon: {
    marginTop: 30,
    '& .MuiIconButton-root': {
      padding: 0,
      color: '#fff',
    },
  },
  appBar: {
    backgroundColor: 'rgba(0,0,0,0)',
    zIndex: theme.zIndex.drawer + 1,
    marginLeft: 105,
    width: `calc(100% - 105px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  logo: {
    padding: 20,
    textAlign: 'center',
    marginRight: 30,
    '& img': {
      height: 58,
    },
  },
  showLogoIcon: {
    marginRight: 20,
  },
  logoIcon: {
    maxWidth: 26,
  },
  smallIcon: {
    maxWidth: 127,
  },
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    position: 'relative',
    text: {
      primary: '#FFFFFF',
    },
    background: '#F8F8F8',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: 240,
  },
  sidebarNav: {
    display: 'flex',
    height: '100%',
    alignItems: 'flex-start',
  },
  navMenu: {
    flex: '1',
    height: '100%',
  },
}))

const Index = ({ list = [] }) => {
  const { cusDispatch } = customDispatch()
  const { authenticated } = authentication()
  const toggle = useSelector((state) => state.app.toggle_drawer)
  const projectRoleState = useSelector((state) => state.app.project_role)
  const projId = useSelector((state) => state.projectAdmin.project_id)
  const projName = useSelector((state) => state.projectAdmin.project_name)

  const { open, drawerFn } = drawer()
  const classes = useStyles()
  const [projectRole, setProjectRole] = useState('')
  const [drawerAction, setDrawerAction] = useState(true)
  const [list1, setList1] = useState([])

  useEffect(() => {
    const { role } = decodeProjectToken()
    setProjectRole(role || '')
  }, [projectRoleState])

  useEffect(() => {
    const { projectName } = decodeProjectToken()
    if (authenticated.projectRole) {
      if (projId) {
        setList1([
          projectAdminList[authenticated.projectRole]({
            projectId: projId,
            projectName,
          }),
        ])
      } else {
        setList1([
          projectAdminList[authenticated.projectRole](decodeProjectToken()),
        ])
      }
    }
  }, [authenticated.projectRole, projId, projName])

  const logoRedirection = () => {
    let redirectTo = '/'
    if (authenticated?.role === 'super_admin') {
      redirectTo = '/'
    } else if (authenticated?.role === 'company_admin') {
      redirectTo = '/view-projects'
    } else {
      redirectTo = '/project-list'
    }
    return redirectTo
  }

  useEffect(() => {
    cusDispatch(sidebarAction(drawerAction))
  }, [drawerAction])

  return (
    <Drawer
      variant="permanent"
      className={classes.drawerWrap}
      classes={{
        paper: clsx({
          [classes.drawerOpen]: drawerAction,
          [classes.drawerClose]: !drawerAction,
        }),
      }}
      open={open}
    >
      <Link
        to={logoRedirection()}
        className={`${classes.logo} ${
          !drawerAction ? classes.showLogoIcon : ''
        }`}
      >
        {!drawerAction ? (
          <img className={classes.logoIcon} src={logoIcon} alt="img" />
        ) : (
          <img className={classes.smallIcon} src={customLogo} alt="img" />
        )}
      </Link>
      <div className={classes.sidebarNav}>
        <div className={classes.navMenu}>
          <Scrollbar>
            <DrawerList list={head(list)} />
            <DrawerList list={last(list)} />
            {list1 && projectRole && <DrawerList list={last(list1)} />}
          </Scrollbar>
        </div>
        <div className={classes.toolbarIcon}>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={() => {
              cusDispatch(toggleDrawerAction(!toggle))
              drawerFn(setDrawerAction(!drawerAction))
            }}
            className={clsx(
              classes.menuButton,
              open && classes.menuButtonHidden,
            )}
          >
            {!drawerAction ? <ChevronRightIcon /> : <ChevronLeftIcon />}
          </IconButton>
        </div>
      </div>
    </Drawer>
  )
}

export default Index
