import {
  DRAWER,
  PATH,
  AUTH,
  THUMB,
  TOAST,
  UPDATE,
  COMP_DATA,
  DELETE_ID,
  NOTIFICATION_INFO,
  COST_REPORT_DATE,
  PROJECT_ID,
  UPDATE_CONFORMATION,
  LOGOUT,
  PROJECT_ADMIN_ROLE,
  APPBAR_DRAWER,
  COMPANY_ROLE,
  APPROVED_NOTIFICATION_ID,
  NOTIFICATION_STATUS,
  CA_SWITCH,
  SIDEBAR_TOOLTIP,
  SIDEBAR_TOUR,
  DASHBOARD_TOUR,
  TOUR_STATUS,
} from '../constants'

export const authAction = (params) => (dispatch) => {
  dispatch({
    type: AUTH,
    payload: params,
  })
}

export const drawerAction = (params) => (dispatch) => {
  dispatch({
    type: DRAWER,
    payload: params,
  })
}

export const pathAction = (params) => (dispatch) => {
  dispatch({
    type: PATH,
    payload: params,
  })
}
export const thumbAction = (params) => (dispatch) => {
  dispatch({
    type: THUMB,
    payload: params,
  })
}
export const notificationStatusAction = (params) => (dispatch) => {
  dispatch({
    type: NOTIFICATION_STATUS,
    payload: params,
  })
}
export const toastAction = (params) => (dispatch) => {
  dispatch({
    type: TOAST,
    payload: params,
  })
}
export const updateAction = (params) => (dispatch) => {
  dispatch({
    type: UPDATE,
    payload: params,
  })
}
export const compDataAction = (params) => (dispatch) => {
  dispatch({
    type: COMP_DATA,
    payload: params,
  })
}
export const conformationAction = (params) => (dispatch) => {
  dispatch({
    type: DELETE_ID,
    payload: params,
  })
}
export const notificationInfoAction = (params) => (dispatch) => {
  dispatch({
    type: NOTIFICATION_INFO,
    payload: params,
  })
}
export const costReportDateAction = (params) => (dispatch) => {
  dispatch({
    type: COST_REPORT_DATE,
    payload: params,
  })
}
export const projectIdAction = (params) => (dispatch) => {
  dispatch({
    type: PROJECT_ID,
    payload: params,
  })
}
export const updateConformationAction = (params) => (dispatch) => {
  dispatch({
    type: UPDATE_CONFORMATION,
    payload: params,
  })
}
export const logoutAction = (params) => (dispatch) => {
  dispatch({
    type: LOGOUT,
    payload: params,
  })
}
export const projectAdminRoleAction = (params) => (dispatch) => {
  dispatch({
    type: PROJECT_ADMIN_ROLE,
    payload: params,
  })
}
export const toggleDrawerAction = (params) => (dispatch) => {
  dispatch({
    type: APPBAR_DRAWER,
    payload: params,
  })
}
export const companyRoleAction = (params) => (dispatch) => {
  dispatch({
    type: COMPANY_ROLE,
    payload: params,
  })
}

export const approvedNotificationId = (params) => (dispatch) => {
  dispatch({
    type: APPROVED_NOTIFICATION_ID,
    payload: params,
  })
}

export const caSwitchAction = (params) => (dispatch) => {
  dispatch({
    type: CA_SWITCH,
    payload: params,
  })
}
export const sidebarAction = (params) => (dispatch) => {
  dispatch({
    type: SIDEBAR_TOOLTIP,
    payload: params,
  })
}
export const sidebarTourAction = (params) => (dispatch) => {
  dispatch({
    type: SIDEBAR_TOUR,
    payload: params,
  })
}
export const dashboardTourAction = (params) => (dispatch) => {
  dispatch({
    type: DASHBOARD_TOUR,
    payload: params,
  })
}
export const tourStatusAction = (params) => (dispatch) => {
  dispatch({
    type: TOUR_STATUS,
    payload: params,
  })
}
