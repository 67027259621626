import { gql } from '@apollo/react-hooks'

export const GET_COMPANY_LIST = gql`
  query getCompanyList($page: Int!,$companyFilter:CompanyFilterInput){
    getCompanyList(pagination: { perPage: ${10}, page: $page },companyFilter:$companyFilter) {
      companyList {
        companyAddress
        companyEmail
        companyName
        id
        status
        companyContact
      }
      error
      pageInfo {
        page
        perPage
        totalCount
      }
    }
  }
`
export const GET_PROJECT_LIST = gql`
  query {
    getProjectList(pagination: { perPage: ${100}, page: ${1} }) {
      error
      projectList {
        budget
        id
        network
        projectName
        projectAdminEmail
        users {
          role
        }
      }
    }
  }
`
export const GET_PROJECT_LIST1 = gql`
  query GetProjectList($page:Int!,$filterInput:JSON){
    getProjectList(
      pagination: { perPage: ${10}, page: $page },      
      filterInput:$filterInput
      ) {
      error
      projectList {
        budget
        id
        network
        projectName
        projectAdminEmail
        users 
      }
      pageInfo{
        totalCount
        page
        perPage
      }
    }
  }
`
export const GET_WITHOUT_ROLE_PROJECT_LIST = gql`
  query {
    getProjectList(pagination: { perPage: ${100}, page: ${1} }) {
      error
      projectList {
        budget
        id
        network
        projectName
        projectAdminEmail
      }
    }
  }
`
export const GET_WITHOUT_ROLE_PROJECT_LIST1 = gql`
  query GetProjectList($page:Int!,$filterInput:JSON){
    getProjectList(
      pagination: { perPage: ${10}, page: $page },
      filterInput:$filterInput
      ) {
      error
      projectList {
        budget
        id
        network
        projectName
        projectAdminEmail
        exportType
        company{
          companyName
        }
        managementType
      }
      pageInfo{
        totalCount
        page
        perPage
      }
    }
  }
`

export const GET_UER_LIST = gql`
  query GetUserList($page:Int!,$email: String,$name:String,$companyRole:String){
    getUserList(pagination: { perPage: ${10}, page: $page } filterData: {email:$email,name:$name,companyRole:$companyRole}) {
      error
      pageInfo {
        totalCount
        perPage
        page
      }
      userList {
        email
        firstName
        lastName
        id
        picUpload
        companyRole
        company {
          companyName
        }
      }
    }
  }
`

export const GET_USER_LIST_BY_COMPANY_ID = gql`
  query GetUserList($companyId: String!,$page:Int!,$email: String,$name:String,$companyRole:String) {
    getUserList(
      pagination: { perPage: ${10}, page: $page }
      filterData: {email:$email,name:$name,companyRole:$companyRole}
      userInput: { companyId: $companyId }
    ) {
      error
      pageInfo {
        totalCount
        perPage
        page
      }
      userList {
        firstName
        lastName
        companyRole
        email
        picUpload
        id
      }
    }
  }
`

export const GET_PENDING_COMPANY_LIST = gql`
  query GetCompanyList($companyFilter: CompanyFilterInput) {
    getCompanyList(
      pagination: { perPage: 10, page: 1 }
      companyFilter: $companyFilter
    ) {
      companyList {
        companyName
        companyEmail
        status
        id
      }
    }
  }
`
export const GET_PENDING_NOTIFICATION_LIST = gql`
  query GetCompanyThumbNotificationList {
    getCompanyList(
      pagination: { perPage: 30, page: 1 }
      companyFilter: { companyStatus: "PENDING" }
    ) {
      companyList {
        companyName
        companyEmail
        status
        id
      }
    }
  }
`

export const GET_NOTIFICATION = gql`
  query {
    getNotification {
      error
      companyList {
        companyName
        companyEmail
        id
        status
      }
    }
  }
`

export const GET_DASHBOARD_DETAIL = gql`
  query {
    getDashboard {
      superAdminDashboard {
        totalCompanies
        totalProjects
        totalUsers
      }
    }
  }
`

export const GET_COMPANY_DETAILS = gql`
  query GetCompanyDetail($companyId: String!) {
    getCompanyDetail(companyId: $companyId) {
      company {
        companyAddress
        companyContact
        companyEmail
        companyName
        file
        isSeen
        projects {
          budget
          network
          projectAdminEmail
          projectName
        }
        status
      }
      error
    }
  }
`
export const GET_PROJECT_DETAILS = gql`
  query GetProjectDetails($projectId: String!) {
    getProjectDetail(projectId: $projectId) {
      error
      project {
        projectLegalEntityName
        projectName
        network
        budget
        efc
        projectApprovedOverUnder
        projectFundingToDate
        projectInvoicedToDate
        insuranceClaim
        anticipatedIncentive
        primaryLocation
        projectAdminEmail
        projectNetworkProductionEmail
        projectNetworkFinanceEmail
        ProjectPayrollContactEmail
        projectBankAccountContactEmail
        projectFundingDue
        projectBankBalancePerBook
        projectBankBalancePerBank
        projectOutStandingChecks
        projectNetworkAuditComplete
        projectResidualsTalent
        projectResidualsOther
        projectBonusDue
        autoFillSubmission
        actualFinalCost
        totalDays
        totalDevelopmentDays
        totalPostProductionDays
        totalPreProductionDays
        totalProductionDays
        qualifiedExpenseTypes
        episodeTypes
        setTypes
        audit
        emailNotification
        company {
          companyAddress
          companyName
          status
          companyContact
          companyEmail
          isDeleted
          isSeen
        }
      }
    }
  }
`
export const GET_USER_LIST = gql`
  query GetUserDetails($userId: String!) {
    getUserDetail(userId: $userId) {
      error
      user {
        companyRole
        email
        hod
        firstName
        lastName
        department {
          departmentName
          id
        }
        company {
          companyAddress
          companyContact
          companyEmail
          companyName
          isSeen
        }
        projects {
          budget
          network
          projectAdminEmail
          projectName
        }
      }
    }
  }
`
export const GET_USER_DATA_LIST = gql`
  query GetUserDetails($userId: String!) {
    getUserDetail(userId: $userId) {
      error
      user {
        companyRole
        email
        firstName
        lastName
        company {
          companyAddress
          companyContact
          companyEmail
          companyName
          isSeen
        }
        projects {
          budget
          network
          projectAdminEmail
          projectName
        }
      }
    }
  }
`
export const GET_SINGLE_USER_DATA_LIST = gql`
  query GetUserDetails($userId: String!) {
    getUserDetail(userId: $userId) {
      error
      user {
        companyRole
        email
        firstName
        lastName
        hod
        department {
          departmentName
          id
        }
        company {
          companyAddress
          companyContact
          companyEmail
          companyName
          isSeen
        }
        projects {
          budget
          network
          projectAdminEmail
          projectName
        }
      }
    }
  }
`

export const GET_PROJECT_COST_REPORT_LIST = gql`
  query GetCostReportList($companyId: String!, $projectId: String,$filterInput:JSON) {
    getCostReportList(
      pagination: { perPage: ${100}, page: ${1} }
      filterInput:$filterInput
      costReportFilterInput: { projectId: $projectId, companyId: $companyId }
    ) {
      costReportList {
        id
        costReportDetail {
          total
        }
        costReportDate
      }
      pageInfo{
        totalCount
        page
        perPage
      }
      error
    }
  }
`
export const GET_PROJECT_COST_REPORT_LIST1 = gql`
  query GetCostReportList($companyId: String!, $projectId: String,$page:Int!,$filterInput:JSON) {
    getCostReportList(
      pagination: { perPage: ${10}, page: $page }
      filterInput:$filterInput
      costReportFilterInput: { projectId: $projectId, companyId: $companyId }
    ) {
      costReportList {
        id
        costReportDetail {
          total
        }
        costReportDate
      }
      error
      pageInfo{
        totalCount
        page
        perPage
      }
    }
  }
`

export const GET_DATE_WISE_COST_REPORT = gql`
  query getCostReportList(
    $companyId: String!
    $projectId: String!
    $costReportDate: String!
  ) {
    getCostReportList(
      pagination: { perPage: ${10}, page: ${1} }
      costReportFilterInput: {
        companyId: $companyId
        projectId: $projectId
        costReportDate: $costReportDate
      }
    ) {
      costReportList {
        additional_notes
        costReportDate
        costReportDateNumber
      }
    }
  }
`

export const GET_COST_REPORT_DETAILS = gql`
  query GetReportList(
    $companyId: String!
    $projectId: String!
    $costReportDate: String!
  ) {
    getCostReportList(
      pagination: { perPage: ${100}, page: ${1} }
      costReportFilterInput: {
        companyId: $companyId
        projectId: $projectId
        costReportDate: $costReportDate
      }
    ) {
      costReportList {
        costReportDetail {
          costReportData
        }
        costReportDate
        costReportDateNumber
      }
      error
    }
  }
`
export const GET_COMPANY_DASHBOARD = gql`
  query {
    getDashboard {
      companyAdminDashboard {
        totalActiveProjects
        totalCompanyUsers
      }
    }
  }
`

export const GET_USER_LIST_BY_PROJECT = gql`
  query GetProjectDetail($projectId: String!) {
    getProjectDetail(projectId: $projectId) {
      project {
        id
        users {
          role
          userId {
            lastName
            firstName
            email
            id
          }
        }
      }
    }
  }
`
export const GET_PROJECT_WISE_ROLE = gql`
  query GetProjectDetail($projectId: String!) {
    getProjectDetail(projectId: $projectId) {
      userProjectDetail {
        projectRole
        projectToken
      }
      error
    }
  }
`
export const GET_USER_LIST_BY_PROJECT_ID = gql`
  query GetUserList($projectId: String!,$page:Int!, $companyId: String!,$email: String,$name:String,$projectRole:String) {
    getUserList(
      pagination: { perPage: ${10}, page: $page }
      filterData: {email:$email,name:$name,projectRole:$projectRole}
      userInput: { projectId: $projectId, companyId: $companyId }
    ) {
      userList {
        companyRole
        email
        firstName
        lastName
        id
        picUpload
        role {
          role
        }
      }
      pageInfo {
        totalCount
        perPage
        page
      }
      error
    }
  }
`

export const GET_APPROVERS = gql`
  query GetApprovalMatrixDetail($companyId: String!, $projectId: String!) {
    getApprovalMatrixDetail(
      approvalMatrixData: { companyId: $companyId, projectId: $projectId }
    ) {
      approvalMatrix {
        approverUsers {
          approvalLimit
          companyId
          id
          isDeleted
          projectId
          role
          userId {
            id
            email
            firstName
          }
        }
        mainApproverId {
          id
        }
      }
      error
    }
  }
`

export const GET_UER_PROFILE = gql`
  query GetUserDetail($userId: String!) {
    getUserDetail(userId: $userId) {
      error
      user {
        firstName
        email
        id
        lastName
        openAiKey
        picUpload
        signatureUpload
        profileCompleted
      }
    }
  }
`
export const GET_APPROVERS_DETAILS = gql`
  query GetApprovalMatrixDetail($companyId: String!, $projectId: String!) {
    getApprovalMatrixDetail(
      approvalMatrixData: { companyId: $companyId, projectId: $projectId }
    ) {
      error
      approvalMatrix {
        approverProjectAdmin {
          id
          role
          userId {
            email
            firstName
            lastName
            id
            picUpload
            hod
            department {
              departmentName
              id
            }
          }
        }
        chartOfAccountRange {
          end
          start
        }
        approverUsers {
          id
          approvalLimit
          approvalRange
          userId {
            firstName
            id
            email
            lastName
            picUpload
            hod
            department {
              departmentName
              id
            }
          }
          role
        }
        mainApprover {
          id
          approvalLimit
          approvalRange
          role
          userId {
            firstName
            email
            id
            isApprover
            picUpload
            hod
            department {
              departmentName
            }
          }
        }
        teamMember {
          role
          userId {
            firstName
            email
            id
            lastName
            picUpload
            hod
            department {
              departmentName
              id
            }
          }
          isDeleted
        }
        mainReviewer {
          approvalLimit
          approvalRange
          role
          userId {
            firstName
            email
            id
            isApprover
            picUpload
            hod
            department {
              departmentName
              id
            }
          }
        }
      }
    }
  }
`
export const SEND_FORGOT_EMAIL = gql`
  query ForgetPassword($email: String!) {
    forgotPassword(email: $email) {
      error
      message
      success
    }
  }
`
export const VERIFY_FP_TOKEN = gql`
  query {
    verifyForgotPasswordToken {
      error
      message
      success
    }
  }
`
export const SUBMISSION_MAIL = gql`
  query CreateMailApprovalRequest(
    $accessToken: String!
    $status: ApprovalRequestStatus!
  ) {
    createMailApprovalRequest(
      mailInput: { accessToken: $accessToken, status: $status }
    ) {
      error
      message
      success
    }
  }
`

export const GET_USER_DETAIL = gql`
  query GetUserDetails($userId: String!) {
    getUserDetail(userId: $userId) {
      error
      user {
        companyRole
        firstName
        lastName
        hod
        department {
          departmentName
          id
        }
        role {
          role
          isDepartmentApprover
        }
      }
    }
  }
`
export const GET_COST_REPORT_FORMAT = gql`
  query GetCostReportFormat($companyId: ID!, $projectId: ID!) {
    getCostReportFormat(
      costReportInput: { companyId: $companyId, projectId: $projectId }
    ) {
      error
      costReportFormat {
        companyId
        costReportHeaders
        file
        projectId
        userId
      }
    }
  }
`
export const GET_COMPANY_PROFILE_DETAILS = gql`
  query GetCompanyDetail($companyId: String!) {
    getCompanyDetail(companyId: $companyId) {
      company {
        companyAddress
        companyEmail
        contractUpload
        file
        id
      }
      error
    }
  }
`
export const GET_PROJECT_DETAIL = gql`
  query getProjectDetail($projectId: String!) {
    getProjectDetail(projectId: $projectId) {
      project {
        autoFillSubmission
        projectLegalEntityName
        projectName
        network
        budget
        efc
        anticipatedIncentive
        primaryLocation
        projectApprovedOverUnder
        projectFundingToDate
        projectInvoicedToDate
        insuranceClaim
        projectAdminEmail
        projectNetworkProductionEmail
        projectNetworkFinanceEmail
        projectBankAccountContactEmail
        projectBankBalancePerBank
        projectBankBalancePerBook
        projectOutStandingChecks
        projectNetworkAuditComplete
        projectResidualsTalent
        projectResidualsOther
        projectBonusDue
        ProjectPayrollContactEmail
        qualifiedExpenseTypes
        episodeTypes
        setTypes
        audit
        emailNotification
      }
      userProjectDetail {
        projectToken
      }
      error
    }
  }
`
export const GET_DEPARTMENT_LIST = gql`
  query {
    getDepartmentList {
      departmentList {
        departmentName
        id
      }
      error
    }
  }
`
export const GET_COMPANY_NAME_LIST = gql`
  query {
    getCompanyNameList {
      companyList {
        companyName
        id
      }
      error
    }
  }
`
export const GET_ALL_PROJECT_LIST = gql`
  query GetProjectNameList($companyId: String!) {
    getProjectNameList(companyId: $companyId) {
      projectNameList {
        id
        projectName
      }
      error
    }
  }
`
export const GET_USER_LIST_CHAT = gql`
query GetUserList($projectId: String!,$page:Int!, $companyId: String!,$email: String,$name:String,$projectRole:String) {
  getUserList(
    pagination: { perPage: ${50}, page: $page }
    filterData: {email:$email,name:$name,projectRole:$projectRole}
    userInput: { projectId: $projectId, companyId: $companyId }
  ) {
    userList {
      companyRole
      email
      firstName
      lastName
      id
      picUpload
      role {
        role
      }
    }
    error
  }
}
`
export const GET_ALL_MESSAGES = gql`
  query GetAllMessage($submissionId: ID!, $projectId: ID!) {
    getMessageList(
      getMessageListInput: {
        submissionId: $submissionId
        projectId: $projectId
      }
    ) {
      error
      messageList {
        id
        createdAt
        mentionId {
          id
          firstName
          lastName
          role {
            role
          }
        }
        projectId
        senderId {
          firstName
          lastName
          id
          role {
            role
          }
          picUpload
        }
        submissionId
        text
      }
    }
  }
`

export const GET_PROJECT_DETAIL_FOR_SUBMISSION = gql`
  query getProjectDetail($projectId: String!) {
    getProjectDetail(projectId: $projectId) {
      project {
        projectName
        qualifiedExpenseTypes
        episodeTypes
        setTypes
        autoFillSubmission
      }
      error
    }
  }
`
export const GET_LOGIN_COUNT = gql`
  query GetSignInCount {
    getSignInCount {
      count
      error
    }
  }
`

export const GET_EMAIL_NOTIFICATION = gql`
  query GetEmailNotification {
    getEmailNotification {
      approvalEmail
      chatEmail
      error
    }
  }
`
export const GET_TOKEN = gql`
  query GetQBToken($id: String!, $code: String!) {
    getQBToken(id: $id, code: $code) {
      token {
        x_refresh_token_expires_in
        refresh_token
        token_type
        access_token
        email
        expires_in
        realmId
        __typename
      }
      error
    }
  }
`

export const GET_QB_REDIRECT_URL = gql`
  query getUrl {
    getQBLoginUri
  }
`

export const REFRESH_QB_TOKEN = gql`
  query RefreshQBToken($refreshToken: String!, $id: ID!) {
    refreshQBToken(refreshToken: { refreshToken: $refreshToken, id: $id }) {
      error
      token {
        access_token
        realmId
        refresh_token
        token_type
        x_refresh_token_expires_in
        expires_in
      }
    }
  }
`

export const GET_COST_REPORT_BUDGET = gql`
  query GetBudgetReport($projectId: ID!) {
    getBudgetReport(projectId: $projectId) {
      error
      report {
        _id
        budgetReportData {
          BUDGETACCT
          DESCRIPTION
          CURRENTCOSTS
          PURCHASEORDERS
          TOTALCOSTTODATE
          ESTIMATETOCOMPLETE
          TOTALESTIMATEFINALCOST
          BUDGET
          VARIANCE
        }
        companyId
        createdAt
        file
        projectId
        total
        updatedAt
        userId
      }
    }
  }
`

export const GET_CHART_GPT_HISTORY = gql`
  query chartGPTHistory($page: Int!) {
    getOpenAIConversations(pagination: { perPage: 20, page: $page }) {
      error
      pageInfo {
        page
        perPage
        totalCount
      }
      result {
        _id
        answer
        createdAt
        isDeleted
        question
        updatedAt
        userId
      }
    }
  }
`

export const GET_STATE = gql`
  query stateName {
    getStateName {
      stateNameList
      error
    }
  }
`

export const GET_DOCUMENT_AI_ACCESS_TOKEN = gql`
  query documentAIAccessToken {
    getDocumentAiAccessToken {
      accessToken
      error
      success
    }
  }
`
export const GET_COST_REPORT_QB_DETAILS = gql`
  query getCostReport(
    $companyId: String!
    $endDate: String!
    $projectId: String!
    $startDate: String!
  ) {
    getCostReport(
      CostReportInput: {
        companyId: $companyId
        endDate: $endDate
        projectId: $projectId
        startDate: $startDate
      }
    ) {
      costReportdata {
        companyId
        costReportAccountConfigurationData {
          accountExpenseCategory
          accountId
          accountName
          accountNumber
          accountOverallBudget
          estimateToComplete
          estimatedFinalCost
          expensesForCurrentPeriod
          isHidden
          parentQboId
          totalCostPlusCommits
          totalExpensesToDate
          totalPurchaseOrders
          variance
        }
        costReportMappedCustomer
        costReportStartDate
        notes
        projectId
        selectedExpensePeriod
        userId
      }
      statusCode
      message
      error
    }
  }
`

export const GET_QUICK_BOOK_CONFIGURATION_DETAILS = gql`
  query GetQuickBookConfigurationDetails(
    $companyId: String!
    $projectId: String!
    $userId: String!
  ) {
    getBudgetConfiguration(
      args: { companyId: $companyId, projectId: $projectId, userId: $userId }
    ) {
      companyId
      costReportMappedCustomer
      costReportStartDate
      customerListData {
        entityName
        isActive
        qboId
      }
      projectStartDate
      notes
      projectId
      userId
      costReportAccountConfigurationData {
        accountId
        accountName
        accountNumber
        accountOverallBudget
        estimatedFinalCost
        isHidden
        numberOfChildAccounts
        parentQboId
        childAccounts {
          accountId
          accountName
          accountNumber
          accountOverallBudget
          estimatedFinalCost
          isHidden
          numberOfChildAccounts
          parentQboId
          childAccounts {
            accountId
            accountName
            accountNumber
            accountOverallBudget
            estimatedFinalCost
            isHidden
            numberOfChildAccounts
            parentQboId
            childAccounts {
              accountId
              accountName
              accountNumber
              accountOverallBudget
              estimatedFinalCost
              isHidden
              numberOfChildAccounts
              parentQboId
              childAccounts {
                accountId
                accountName
                accountNumber
                accountOverallBudget
                estimatedFinalCost
                isHidden
                numberOfChildAccounts
                parentQboId
                childAccounts {
                  accountId
                  accountName
                  accountNumber
                  accountOverallBudget
                  estimatedFinalCost
                  isHidden
                  numberOfChildAccounts
                  parentQboId
                  childAccounts {
                    accountId
                    accountName
                    accountNumber
                    accountOverallBudget
                    estimatedFinalCost
                    isHidden
                    numberOfChildAccounts
                    parentQboId
                    childAccounts {
                      accountId
                      accountName
                      accountNumber
                      accountOverallBudget
                      estimatedFinalCost
                      isHidden
                      numberOfChildAccounts
                      parentQboId
                      childAccounts {
                        accountId
                        accountName
                        accountNumber
                        accountOverallBudget
                        estimatedFinalCost
                        isHidden
                        numberOfChildAccounts
                        parentQboId
                        childAccounts {
                          accountId
                          accountName
                          accountNumber
                          accountOverallBudget
                          estimatedFinalCost
                          isHidden
                          numberOfChildAccounts
                          parentQboId
                          childAccounts {
                            accountId
                            accountName
                            accountNumber
                            accountOverallBudget
                            estimatedFinalCost
                            isHidden
                            numberOfChildAccounts
                            parentQboId
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

export const SYNC_QUICK_BOOK_CUSTOMERS = gql`
  query GetSyncQuickBookCustomers {
    syncQuickBookCustomers {
      message
      success
      error
    }
  }
`
export const SYNC_QUICK_BOOK_ACCOUNTS = gql`
  query GetSyncQuickBookAccounts {
    syncQuickBookAccounts {
      error
      message
      success
    }
  }
`
export const DOWNLOAD_COST_REPORT_PDF = gql`
  query GetDownloadCostReportDetails(
    $exportCostReportInput: ExportCostReportInput!
  ) {
    exportPdfCostReportData(ExportCostReportInput: $exportCostReportInput) {
      file
      message
      status
    }
  }
`

export const GET_FILTER_TEMPLATE_DATA = gql`
  query getFilterTemplateDate($filterType: ReportBuilderType!) {
    getFilterTemplates(filterType: $filterType) {
      error
      templates {
        companyId
        filterType
        filters
        isDeleted
        name
        projectId
        templateId
        userId
      }
    }
  }
`
