import { useMutation } from '@apollo/client'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { COMPANY_VERIFICATION } from '../graphQL/mutations/mutation'
import { SUBMISSION_APPROVAL } from '../graphQL/mutations/submission'
import {
  setManagerThumbPerAction,
  submissionNotificationAction,
  submissionThumbIdAction,
} from '../store/actions/projectAdmin'
import {
  bellNotification,
  chargeCreditCard,
  checkRequest,
  checkRequestConstant,
  creditCardCharge,
  orderPurchase,
  purchaseOrder,
  thumbNotification,
  toastError,
  toastSuccess,
  wireTransfer,
  wireTransferConstant,
  invoiceSummary,
  invoiceSummaryType,
  apExpress,
} from '../utils/constants'
import { equal } from '../utils/javascript'
import { reusableMutation } from './commonFunctions'
import customDispatch from './customDispatch'
import dispatchToastMsg from './dispatchToastMsg'

const verifyCompany = () => {
  const [verify, { loading, error, data }] = useMutation(COMPANY_VERIFICATION)
  const handleSubmit = (id, status) => {
    verify({
      variables: {
        companyId: id,
        status,
      },
    })
  }
  return { handleSubmit, loading, error, data }
}
export default verifyCompany

export const approveRejectSubmission = () => {
  const { cusDispatch } = customDispatch()
  const [approvalDetail, setApprovalDetail] = useState('')
  const { toast } = dispatchToastMsg()
  const [
    setSubmissionApproval,
    { loading: submissionApprovalLoading, data: submissionApprovalData },
  ] = reusableMutation(SUBMISSION_APPROVAL)
  const notificationType = useSelector(
    (state) => state.projectAdmin.notification_type,
  )

  const approvalAction = (approverType, id, submissionType) => {
    const setSubmissionType = () => {
      if (equal(notificationType, bellNotification)) {
        if (equal(submissionType, orderPurchase)) return purchaseOrder
        if (equal(submissionType, chargeCreditCard)) return creditCardCharge
        if (equal(submissionType, wireTransferConstant)) return wireTransfer
        if (equal(submissionType, checkRequestConstant)) return checkRequest
        if (equal(submissionType, invoiceSummaryType)) return invoiceSummary
        if (equal(submissionType, apExpress)) return invoiceSummary
      }
      if (equal(notificationType, thumbNotification)) {
        if (equal(submissionType, purchaseOrder)) return purchaseOrder
        if (equal(submissionType, creditCardCharge)) return creditCardCharge
        if (equal(submissionType, wireTransfer)) return wireTransfer
        if (equal(submissionType, checkRequest)) return checkRequest
        if (equal(submissionType, invoiceSummary)) return invoiceSummary
        if (equal(submissionType, apExpress)) return invoiceSummary
      }

      return ''
    }
    setApprovalDetail({ id, approverType })
    setSubmissionApproval({
      variables: {
        requestType: setSubmissionType(),
        approvalRequestId: id,
        status: approverType,
      },
    })

    cusDispatch(submissionThumbIdAction(id))
  }

  useEffect(() => {
    const responseData = submissionApprovalData?.approvalRequest?.createApproval
    if (responseData?.error) {
      toast(toastError, responseData?.error)
      cusDispatch(setManagerThumbPerAction(responseData))
    }
    if (responseData?.message) {
      toast(toastSuccess, responseData?.message)
      cusDispatch(submissionNotificationAction(approvalDetail))
      cusDispatch(setManagerThumbPerAction(responseData))
    }
  }, [submissionApprovalData])

  return { approvalAction, submissionApprovalLoading }
}
