import { lazy } from 'react'
import hoc from '../hoc'

const Dashboard = lazy(() => import('../presentation/Dashboard/CompanyAdmin'))
const ViewProjects = lazy(() =>
  import('../presentation/Dashboard/CompanyAdmin/ViewProjects'),
)
const AddTeamMember = lazy(() =>
  import('../presentation/Dashboard/CompanyAdmin/AddTeamMember'),
)
const ViewTeamMember = lazy(() =>
  import('../presentation/Dashboard/CompanyAdmin/ViewTeamMember'),
)
// const ViewVendors = lazy(() =>
//   import('../presentation/Dashboard/CompanyAdmin/ViewVendors'),
// )
// const ViewCostReports = lazy(() =>
//   import('../presentation/Dashboard/CompanyAdmin/ViewCostReport'),
// )
const Integration = lazy(() =>
  import('../presentation/Dashboard/CompanyAdmin/Integration'),
)
const ViewProjectDetails = lazy(() =>
  import('../presentation/Dashboard/CompanyAdmin/ProjectDetails'),
)
const ViewUserDetails = lazy(() =>
  import('../presentation/Dashboard/CompanyAdmin/UserDetails'),
)
const AddProject = lazy(() =>
  import('../presentation/Dashboard/CompanyAdmin/AdminCreateProject'),
)
const AddCompanyAdmin = lazy(() =>
  import('../presentation/Dashboard/CompanyAdmin/AddCompanyAdmin'),
)
// const ViewCostReportDetails = lazy(() =>
//   import('../presentation/Dashboard/CompanyAdmin/CostReportDetails'),
// )
const AddChartOfAccount = lazy(() =>
  import('../presentation/Dashboard/CompanyAdmin/AddChartAccount'),
)
// const AddCostReport = lazy(() =>
//   import('../presentation/Dashboard/CompanyAdmin/AddCostReport'),
// )

// const AddCostReportFormat = lazy(() =>
//   import('../presentation/Dashboard/CompanyAdmin/AddCostFormat'),
// )
// const ChangePassword = lazy(() => import('../presentation/ChangePassword'))
const UserProfile = lazy(() => import('../presentation/UserProfile'))

const redirectUrl = '/login'
export default [
  {
    path: '/dashboard',
    component: hoc(Dashboard),
    auth: true,
    redirectUrl,
  },
  {
    path: '/view-projects',
    component: hoc(ViewProjects),
    auth: true,
    redirectUrl,
  },
  {
    path: '/team-member',
    component: hoc(ViewTeamMember),
    auth: true,
    redirectUrl,
  },
  {
    path: '/add-member',
    component: hoc(AddTeamMember),
    auth: true,
    redirectUrl,
  },
  {
    path: '/add-company-admin',
    component: hoc(AddCompanyAdmin),
    auth: true,
    redirectUrl,
  },
  {
    path: '/create-project',
    component: hoc(AddProject),
    auth: true,
    redirectUrl,
  },
  // {
  //   path: '/view-vendors',
  //   component: hoc(ViewVendors),
  //   auth: true,
  //   redirectUrl,
  // },
  // {
  //   path: '/view-cost-report',
  //   component: hoc(ViewCostReports),
  //   auth: true,
  //   redirectUrl,
  // },
  // {
  //   path: '/add-cost-report',
  //   component: hoc(AddCostReport),
  //   auth: true,
  //   redirectUrl,
  // },
  {
    path: '/integrations',
    component: hoc(Integration),
    auth: true,
    redirectUrl,
  },
  // {
  //   path: '/add-cost-report-format',
  //   component: hoc(AddCostReportFormat),
  //   auth: true,
  //   redirectUrl,
  // },
  // {
  //   path: '/change-password',
  //   component: hoc(ChangePassword),
  //   auth: true,
  //   redirectUrl,
  // },
  {
    path: '/project-details/:id',
    component: hoc(ViewProjectDetails),
    auth: true,
    redirectUrl,
  },
  {
    path: '/user-details/:id',
    component: hoc(ViewUserDetails),
    auth: true,
    redirectUrl,
  },
  // {
  //   path: '/cost-report-details/:projectId/:id',
  //   component: hoc(ViewCostReportDetails),
  //   auth: true,
  //   redirectUrl,
  // },
  {
    path: '/add-chart-account',
    component: hoc(AddChartOfAccount),
    auth: true,
    redirectUrl,
  },
  {
    path: '/user-profile',
    component: hoc(UserProfile),
    auth: true,
    redirectUrl,
  },
]
