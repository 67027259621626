import React, { useEffect, useState } from 'react'
import CssBaseline from '@material-ui/core/CssBaseline'
import AppBar from '@material-ui/core/AppBar'
import clsx from 'clsx'
import Toolbar from '@material-ui/core/Toolbar'
import AccountCircleIcon from '@material-ui/icons/AccountCircle'
import { makeStyles } from '@material-ui/core/styles'
import Badge from '@material-ui/core/Badge'
import MenuItem from '@material-ui/core/MenuItem'
import { toast as toastMsg } from 'react-toastify'
import Menu from '@material-ui/core/Menu'
import { useSelector } from 'react-redux'
import { ReactComponent as NotificationsIcon } from '../../assets/images/Icons/Notification.svg'
import { ReactComponent as ThumbUpAltOutlinedIcon } from '../../assets/images/Icons/Thumbs.svg'
import CustomButton from '../CustomButton'
import { isLoggedIn } from '../../utils/localStorage'
import NotificationDialogue from '../NotificationDialogue'
import getPendingCompanyList from '../../containers/getPendingCompanyList'
import getNotification from '../../containers/getNotification'
import { equal, notEqual } from '../../utils/javascript'
import { getNewCompanyList } from '../../containers/getSubscriptionData'
import bellThumbNotificationInfo from '../../containers/bellThumbNotificationInfo'
import Image from '../Image'
import { decodeProjectToken, decodeToken } from '../../hooks/decodeToken'
import appBar from '../../containers/appBar'
import { companyProjectRole, superAdminRole } from '../../utils/constants'
import {
  GET_COMPANY_PROFILE_DETAILS,
  GET_UER_PROFILE,
} from '../../graphQL/queries/query'
import { reusableLazyQuery } from '../../containers/commonFunctions'
import CompanyLogo from '../../assets/images/logos/company-logo.png'
import Loader from '../Loader'
import getChatSubscriptionData from '../../containers/projectAdmin/getChatSubscriptionData'
import { keys } from '../../utils/regex'
import ResponseMsg from '../ResponseMsg'
import customDispatch from '../../containers/customDispatch'
import { chartGPTSwitch } from '../../store/actions/projectAdmin'

const drawerWidth = 300
const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  toolbar: {
    paddingRight: 24,
    justifyContent: 'flex-end',
  },
  toolbarIcon: {
    marginTop: 30,
    '& .MuiIconButton-root': {
      padding: 0,
    },
  },
  appBar: {
    backgroundColor: 'rgba(0,0,0,0)',
    zIndex: theme.zIndex.drawer + 1,
    marginLeft: 105,
    width: `calc(100% - 105px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: 'none',
  },
  title: {
    flexGrow: 1,
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: 240,
  },
  userMenu: {
    marginRight: 15,
    '& .MuiButton-label': {
      '& svg': {
        marginRight: 10,
      },
    },
  },
  icon: {
    '& span': {
      cursor: 'pointer',
      '&:last-child': {
        marginLeft: '15px',
      },
    },
  },
  userIcon: {
    width: '30px',
    height: '30px',
    overflow: 'hidden',
    borderRadius: '50px',
    marginRight: '10px',
    '& > span': {
      width: '100% !important',
      height: '100% !important',
      objectFit: 'cover',
    },
  },
  userDropDown: {
    marginTop: 25,
    borderRadius: '8px',
    maxHeight: '450px',
    top: '43px !important',
    '& .MuiList-padding': {
      padding: '10px 16px',
    },
    /* '&:before': {
      position: 'absolute',
      content: "''",
      top: 0,
      width: 0,
      height: 0,
      borderLeft: '50px solid transparent',
      borderRight: '50px solid transparent',
      borderBottom: '100px solid red',
    }, */
  },
  notificationAlert: {
    position: 'relative',
    // '&:before': {
    //   content: "''",
    //   position: 'absolute',
    //   right: 0,
    //   left: -2,
    //   width: 6,
    //   height: 6,
    //   bottom: -12,
    //   backgroundColor: '#FF3249',
    //   borderRadius: '100%',
    //   margin: [[0, 'auto']],
    // },
  },
  profileView: {
    '& .MuiList-padding': {
      padding: '8px 0px',
      '& .MuiListItem-root': {
        padding: '6px 22px',
      },
    },
  },
  companyLogo: {
    height: '38px',
    display: 'flex',
    padding: '6px',
    marginRight: '4px',
    '& img': {
      maxHeight: '100%',
      margin: 'auto',
    },
  },
  notificationDropdown: {
    left: 'auto !important',
    right: '0',
  },
}))

const Index = () => {
  const {
    toggle,
    anchorEl,
    anchorEl1,
    anchorEl2,
    handleClick,
    handleClose,
    setAnchorEl,
    logout,
  } = appBar()

  const { cusDispatch } = customDispatch()
  const [userInfo, setUserInfo] = useState({})
  const [profileLogo, setProfileLogo] = useState('')
  const [
    { notificationData, notificationCount, setNotificationCount, loading },
  ] = getNotification()

  const [{ thumbData }] = getPendingCompanyList({
    setNotificationCount,
    notificationCount,
  })
  const approverUser = useSelector((state) => state.projectAdmin.approver_user)
  const [{ cancelNotification }] = bellThumbNotificationInfo()
  const classes = useStyles()
  const { userId, companyId, companyProfile } = decodeToken()
  const { role: projectRole } = decodeProjectToken()
  const { role } = isLoggedIn()
  // const [{ comLen, thumbCount }] = getNewCompanyList()
  const [{ comLen, thumbCount }] =
    role === 'super_admin'
      ? getNewCompanyList()
      : [{ comLen: [], thumbCount: undefined }]
  const [getUserProfile, { data: profileData }] =
    reusableLazyQuery(GET_UER_PROFILE)
  const [getCompanyProfileData, { data: comProData }] = reusableLazyQuery(
    GET_COMPANY_PROFILE_DETAILS,
  )

  const { chatNotification } =
    role !== 'super_admin' && role !== 'company_admin'
      ? getChatSubscriptionData()
      : { chatNotification: {} }

  useEffect(() => {
    if (keys(chatNotification).length) {
      toastMsg.success(`${chatNotification.name} ${chatNotification.msg}`, {
        className: 'alert-success',
      })
    }
  }, [chatNotification])

  useEffect(() => {
    getUserProfile({
      variables: {
        userId,
      },
    })
  }, [userId])

  useEffect(() => {
    if (companyId) {
      if (equal(role, 'company_admin')) {
        getCompanyProfileData({
          variables: {
            companyId,
          },
        })
      }
    }
  }, [companyId])

  useEffect(() => {
    setProfileLogo(comProData?.getCompanyDetail?.company?.file || '')
  }, [comProData])

  useEffect(() => {
    if (companyProfile) {
      setProfileLogo(companyProfile || '')
    }
  }, [companyProfile])

  useEffect(() => {
    if (profileData) {
      setUserInfo(profileData?.getUserDetail?.user)
    }
    if (profileData?.getUserDetail?.user?.openAiKey) {
      cusDispatch(chartGPTSwitch(true))
    }
  }, [profileData])

  const handleCloseTwo = () => {
    cancelNotification()
    handleClose('two')
  }
  const handleCloseThree = () => {
    cancelNotification()
    handleClose('three')
  }

  const handleNotificationCondition = () => {
    let valueFlag = 0
    if (
      equal(projectRole, companyProjectRole) ||
      equal(projectRole, 'MANAGER')
    ) {
      valueFlag = approverUser ? notificationCount.thumbCount : 0
    } else {
      valueFlag = thumbCount || notificationCount.thumbCount
    }
    return valueFlag
  }

  const handleBellNotification = () => {
    let value = 0
    if (equal(role, superAdminRole)) {
      value = (comLen && comLen) || notificationCount.notificationCount
    }
    if (
      equal(projectRole, companyProjectRole) ||
      equal(projectRole, 'MANAGER')
    ) {
      value = approverUser ? notificationCount.notificationCount : 0
    }
    if (projectRole === 'REVIEWER') {
      value = notificationCount?.notificationCount
    }

    return value > 35 ? `${35}+` : value
  }

  return (
    <>
      <CssBaseline />
      {loading && <Loader />}
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, toggle && classes.appBarShift)}
        color="inherit"
        elevation={0}
      >
        <Toolbar className={classes.toolbar}>
          {keys(userInfo).length ? (
            <>
              {notEqual(role, 'super_admin') && (
                <div className={classes.companyLogo}>
                  <img src={profileLogo || CompanyLogo} alt="Contract-Upload" />
                </div>
              )}
              <CustomButton
                className={classes.userMenu}
                onClick={(e) => handleClick(e, 'one')}
              >
                {userInfo?.picUpload ? (
                  <span className={classes.userIcon}>
                    <Image src={userInfo?.picUpload} alt="user-profile" />
                  </span>
                ) : (
                  <AccountCircleIcon fontSize="large" color="primary" />
                )}

                <span>
                  {userInfo?.firstName || userInfo?.lastName || 'N/A'}{' '}
                </span>
              </CustomButton>
            </>
          ) : null}

          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={() => setAnchorEl(false)}
            classes={{
              paper: `${classes.userDropDown} ${classes.profileView}`,
            }}
            getContentAnchorEl={null}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
          >
            <MenuItem onClick={() => handleClose('one')}>Profile</MenuItem>

            <MenuItem onClick={logout}>Logout</MenuItem>
          </Menu>
          {role !== 'company_admin' && (
            <div className={classes.icon}>
              <Badge
                badgeContent={handleBellNotification()}
                color="primary"
                className={classes.notificationAlert}
                overlap="rectangular"
              >
                <NotificationsIcon onClick={(e) => handleClick(e, 'two')} />
              </Badge>
              <Menu
                id="simple-menu"
                anchorEl={anchorEl1}
                getContentAnchorEl={null}
                keepMounted
                open={Boolean(anchorEl1)}
                onClose={() => handleCloseTwo()}
                classes={{
                  paper: `${classes.userDropDown} ${classes.notificationDropdown}`,
                }}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'center',
                }}
              >
                <NotificationDialogue
                  open
                  handleClose={cancelNotification}
                  title="Notification"
                  content={notificationData || []}
                />
              </Menu>
              <Badge
                badgeContent={handleNotificationCondition()}
                color="primary"
                overlap="rectangular"
              >
                <ThumbUpAltOutlinedIcon
                  onClick={(e) => handleClick(e, 'three')}
                />
              </Badge>

              <Menu
                id="simple-menu"
                anchorEl={anchorEl2}
                getContentAnchorEl={null}
                keepMounted
                open={Boolean(anchorEl2)}
                onClose={() => handleCloseThree()}
                classes={{ paper: classes.userDropDown }}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'center',
                }}
              >
                <NotificationDialogue
                  open
                  onClose={() => handleCloseThree()}
                  handleClose={cancelNotification}
                  title="List of Pending Companies Approval"
                  content={thumbData || []}
                />
              </Menu>
            </div>
          )}
        </Toolbar>
      </AppBar>
      <ResponseMsg />
    </>
  )
}

export default Index
