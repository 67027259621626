import { createTheme, responsiveFontSizes } from '@material-ui/core'

import palette from './palette'

const mode = sessionStorage.getItem('themeMode') || 'light'

const theme = responsiveFontSizes(
  createTheme({
    palette: palette[mode],
    layout: {
      contentWidth: 1140,
    },
    typography: {
      fontFamily: 'Lato',
    },
    zIndex: {
      appBar: 1200,
      drawer: 1100,
    },
    overrides: {
      MuiButton: {
        root: {
          textTransform: 'capitalize',
          fontSize: 16,
          boxShadow: 'none',
          borderRadius: 12,
          padding: [[7, 30]],
          '& svg + span': {
            marginLeft: 5,
          },
        },
        containedSecondary: {
          color: 'white',
        },
      },
    },
  }),
)

export default theme
