export const drawerWidth = 300
export const superAdminRole = 'super_admin'
export const companyAdminRole = 'company_admin'
export const projectAdminRole = 'project_admin'
export const managerRole = 'manager'
export const assistantRole = 'assistant'
export const reviewerRole = 'reviewer'
export const commonRole = 'common'
export const projectRoleAdmin = 'project_admin'
export const projectRoleManger = 'manager'
export const projectRoleBasic = 'basic'
export const projectRoleAssistant = 'assistant'
export const projectRoleReviewer = 'reviewer'
export const companyRole = 'companyRole'
export const members = 'members'
export const companyProjectAdmin = 'Project Admin'
export const companyProjectRole = 'PROJECT_ADMIN'
export const companyAdmin = 'COMPANY_ADMIN'
export const companyManagerRole = 'MANAGER'
export const companyAssistantRole = 'ASSISTANT'
export const companyReviewerRole = 'REVIEWER'
export const mainApprover = 'Main Approver'
export const companyName = 'companyName'
export const file = 'file'
export const id = 'id'
export const toastError = 'error'
export const toastSuccess = 'Success'
export const fileUpload = 'fileUpload'
export const approvalProjectId = 'approval_project_id'
export const companyTeamMember = 'Team Members'
export const approvers = 'Approvers'
export const userId = 'userId'
export const PENDING = 'PENDING'
export const underscore = '_'
export const space = ' '
export const Approve = 'APPROVED'
export const Reject = 'REJECTED'
export const purchaseOrder = 'PURCHASE_ORDER'
export const creditCardCharge = 'CREDIT_CARD_CHARGE'
export const wireTransfer = 'WIRE_TRANSFER'
export const checkRequest = 'CHECK_REQUEST'
export const allSubmission = 'ALL_SUBMISSION'
export const hotel = 'HOTEL'
export const equipmentRental = 'EQUIPMENT_RENTAL'
export const travel = 'TRAVEL'
export const locationRental = 'LOCATION_RENTAL'
export const purchaseOrderId = 'purchaseOrderId'
export const wireTransferId = 'wireTransferId'
export const checkRequestId = 'checkRequestId'
export const creditCardChargeId = 'creditCardChargeId'
export const apInvoiceId = 'apInvoiceId'
export const bellNotification = 'bellNotification'
export const thumbNotification = 'thumbNotification'
export const w9Upload = 'w9Upload'
export const contractUpload = 'contractUpload'
export const invoice = 'invoice'
export const invoiceUpload = 'invoiceUpload'
export const purchaseOrderConstant = 'PURCHASE_ORDER'
export const picUpload = 'picUpload'
export const signatureUpload = 'signatureUpload'
export const orderPurchase = 'Purchase Order'
export const chargeCreditCard = 'Credit Card Charge'
export const wireTransferConstant = 'Wire Transfer'
export const checkRequestConstant = 'Check Request'
export const SUBMITTED = 'SUBMITTED'
export const CREATED = 'CREATED'
export const REVIEWED = 'REVIEWED'
export const PARTIALLY_REVIEWED = 'PARTIALLY_REVIEWED'
export const EDITED = 'EDITED'

export const deleteContent = 'Are you sure you want to delete?'
export const deleteProjectMsg = 'Delete Project'
export const deleteUserMsg = 'Delete User'
export const deleteCompanyMsg = 'Delete Company'
export const deleteCostReportMsg = 'Delete Cost Report'

export const updateComSubtitle = 'Update company information below...'
export const updateProSubtitle = 'Update project information below...'
export const updateUserSubtitle = 'Update user information below...'
export const updateCostReportSubtitle =
  'Upload cost report information below...'
export const updateComTitle = 'Update company information'
export const updateUserTitle = 'Update user information'
export const updateProTitle = 'Update project information'
export const updateCostReportTitle = 'Update cost report information'
export const PO = 'Purchase Order'
export const WT = 'Wire Transfer'
export const CCC = 'Credit Card Charge'
export const CR = 'Check Request'
export const w9 = 'w9'
export const POP = 'proofOfPayment'
export const POId = 'purchaseOrderId'
export const WTId = 'wireTransferId'
export const CRId = 'checkRequestId'
export const CCCId = 'creditCardChargeId'

export const companyRoleTpe = [
  'ASSISTANT',
  'MANAGER',
  'PROJECT_ADMIN',
  'REVIEWER',
]
export const conduitRolesList = [
  superAdminRole,
  companyAdminRole,
  projectAdminRole,
  commonRole,
]
export const doubleGT = '>>'
export const doubleLT = '<<'
export const lessThan = '<'
export const greaterThan = '>'

export const companyRoleType = [
  'ASSISTANT',
  'MANAGER',
  'PROJECT_ADMIN',
  'REVIEWER',
  'COMPANY_ADMIN',
]

export const hodList = [
  { name: 'True', value: true },
  { name: 'False', value: false },
]
export const addUserRoles = ['project_admin', 'manager', 'reviewer']
export const projectNameRole = ['PROJECT_ADMIN', 'MANAGER', 'REVIEWER']
export const invoiceSummary = 'AP_INVOICE'
export const updateProjectDetailRole = ['PROJECT_ADMIN', 'REVIEWER']
export const formsReference = [wireTransfer, checkRequest, invoiceSummary]
export const restrictedVendorName = [purchaseOrderConstant, creditCardCharge]
export const apExpress = 'Ap Expresses'
export const invoiceSummaryType = 'Invoice summary'
export const allowNegativeFormTypes = [
  purchaseOrderConstant,
  wireTransfer,
  checkRequest,
  creditCardCharge,
]

export const projectDetailChange = [
  'efc',
  'projectApprovedOverUnder',
  'projectOutStandingChecks',
]

export const currencySection = [
  'projectApprovedOverUnder',
  'insuranceClaim',
  'projectFundingToDate',
  'projectInvoicedToDate',
  'projectBankBalancePerBank',
  'projectBankBalancePerBook',
  'projectOutStandingChecks',
  'budget',
  'efc',
  'projectBonusDue',
  'projectResidualsOther',
  'projectResidualsTalent',
  'anticipatedIncentive',
]

export const projectDetailsKeyReplace = [
  {
    name: 'efc',
    replace: 'EFC',
  },
  {
    name: 'projectApprovedOverUnder',
    replace: 'Approved Over/Under',
  },
  {
    name: 'projectOutStandingChecks',
    replace: 'Outstanding Checks',
  },
]

export const dashboardUserProfile = 'Upload your profile picture'
export const dashboardChangePass = 'Change your password'
export const dashboardSignature = 'Add your signature'
export const dashboardViewSub = 'View a submission'
export const dashboardExportSub = 'Export a submission'
export const dashboardFillProProfile = 'Fill in the project profile'
export const dashboardUpdateProDetail =
  'Update project details and information.'
export const dashboardSetUpApprovalRights = 'Set user approval rights'
export const dashboardAddUser = 'Add a new user'
export const dashboardViewPO = 'View purchase orders'
export const dashboardViewCR = 'View check requests'
export const dashboardViewWT = 'View wire transfers'
export const dashboardViewCCR = 'View credit cards'
export const dashboardCreateSub = 'Create a new submission'
export const dashboardApproveSub = 'Approve a submission'
export const dashboardFiles = 'Files'
export const dashboardCostReport = 'Cost reports'
export const dashboardGL = 'General ledger'
export const dashboardAudit = 'Audit'
export const dashboardCreateProject = 'Create a new project'

export const proDetails = 'project-details'
export const userProfile = 'user-profile'
export const addSub = 'add-submission'
export const viewSub = 'view-submission'
export const addUser = 'add-user'
export const approvalMatrix = 'approval-matrix'
export const costReport = 'view-cost-report'
export const generalLedger = 'general-ledger'
export const audit = 'audit'
export const Created = 'Ready to Approve'
export const Submitted = 'Ready to Review'
export const Reviewed = 'Ready to Input'
export const EditedSubmitted = 'EDITED'

export const details = 'Details'
export const dashboardTitle = 'Dashboard'
export const companies = 'Companies'
export const projects = 'Projects'
export const users = 'Users'
export const profile = 'Profile'
export const teamMembers = 'Team members'
export const projectDetailTitle = 'Project Detail'
export const approvalMatrixTitle = 'Approval Matrix'
export const submissionTitle = 'Submission'
export const submissionDetailTitle = 'Submission Detail'
export const ReportBuilderTitle = 'Report Builder'
export const CostReportTitle = 'Cost Report'
export const GeneralLedgerTitle = 'General Ledger'
export const AuditTitle = 'Audit'
export const IntegrationTitle = 'Integration'
export const addChartTitle = 'Add Chart'

export const helmetDashboardKeywords = 'Project dashboards - Conduiit'
export const helmetDashboardDescription =
  'View cost report summary information at a glance with our customizable dashboards and indicators'

export const helmetApprovalMatrixKeywords = 'Approval Matrix - Conduiit'
export const helmetApprovalMatrixDescription =
  'Manage department and production approval chains easily with our approval matrix'

export const helmetSubmissionKeywords =
  'Pre-built and customizable forms- Conduiit'
export const helmetSubmissionDescription =
  'Simplify the purchase order and payment request process with digital forms'

export const helmetCostReportKeywords =
  'Tables, views and File Manager - Conduiit'
export const helmetCostReportDescription =
  'View details and supporting documentation on screen or export to excel or PDF'

export const helmetIntegrationKeywords =
  'Slack, Google Drive, Asana, Twilio, Oracle Netsuite, Workday, Quick Book - Conduiit'
export const helmetIntegrationDescription =
  'Integration with other popular platforms like Slack, Google Drive, Asana, Twilio, Oracle Netsuite, Workday, Quick Book or production accounting software'
export const invalidToken = 'Invalid Token'

export const showDollarKey = ['Commitment', 'Amount']

export const emailNotification = 'Email Notification'

export const dateNaN = 'NaN-NaN-NaN'

export const constReportUpdateNote =
  'Enter any additional information about this report as a note to display it at the footer of the report when it is exported.'

export const costReportAccessTitle =
  'Please log in to Integrations to access the cost report.'

export const costReportIntegrationBtnName = 'Go To Integrations'

export const accountCodeNotfound = 'Account code not found!'

export const noDataFoundMessage = 'No Data Found'
export const dateErrorMessage =
  'The start date selected is before the project starting date'

export const invalidDateSelectMessage =
  'Invalid date range! Start date is smaller then End date.'

export const costReportFetchMessage = 'Cost Report Fetched Successfully'
